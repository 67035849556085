<template>
    <v-container
        fluid
        grid-list-xl
    >
        <common-v-alert
            v-bind="deleteDialog.errorAlert.properties"
            class="fixed-alert"
            dismissible
        />

        <v-layout
            justify-center
            wrap
        >
            <edit-user-dialog
                v-model="editDialog.show"
                :user="editDialog.currentUser"
                @save="(payload) => updateUser(payload.id, payload.payload)"
                @cancel="editDialog.show = false"
            />

            <delete-user-dialog
                :show="deleteDialog.dialog.properties.show"
                :status="deleteDialogStatus"
                :user="deleteDialog.currentUser"
                @confirm="() => removeUser(deleteDialog.currentUser)"
                @cancel="deleteDialog.dialog.controller.closeDialog"
            />

            <v-flex md12>
                <v-layout>
                    <v-flex md6>
                        <v-text-field
                            v-model="search.searchString.current"
                            label="Search"
                        />

                        <v-select
                            v-model="search.searchBy.value"
                            :items="search.searchBy.options"
                            label="Search criteria"
                            @change="refreshUsers()"
                        />
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex md12>
                <material-card
                    :title="'Users (' + total + ')'"
                    color="primary"
                >
                    <v-data-table
                        :headers="headers"
                        :items="displayUsers"
                        :loading-data="search.loading"
                        hide-actions
                    >
                        <template
                            slot="headerCell"
                            slot-scope="{ header }"
                        >
                            <span
                                class="subheading font-weight-light text-primary text--darken-3"
                                v-text="header.text"
                            />
                        </template>

                        <template
                            slot="items"
                            slot-scope="{ item }"
                        >
                            <td>{{ item.id }}</td>
                            <td>{{ item.username }}</td>
                            <td>{{ item.email }}</td>
                            <td>{{ item.created | formatDate('DD MMMM YYYY HH:mm:ss') }}</td>
                            <td>{{ item.scope | formatScope }}</td>
                            <td>{{ item.vendor ? item.vendor.join(', ') : 'No vendor assigned' }}</td>
                            <td v-if="!item.removalStatus.loading">
                                <a
                                    :href="`/dashboard/flows?userId-query=${item.id}`"
                                ><v-icon>mdi-sitemap</v-icon></a>&nbsp;
                                <v-icon
                                    class="action-icon"
                                    @click="editUser(item)"
                                >
                                    mdi-pencil
                                </v-icon>&nbsp;
                                <v-icon
                                    v-if="currentUser.id && currentUser.id !== item.id"
                                    class="action-icon"
                                    @click="deleteUser(item)"
                                >
                                    mdi-delete
                                </v-icon>
                            </td>
                            <td v-else>
                                Removing user...
                            </td>
                        </template>
                    </v-data-table>
                </material-card>
            </v-flex>
        </v-layout>

        <v-layout justify-center>
            <v-pagination
                v-if="total"
                v-model="search.page"
                :length="paginatorLength"
                :total-visible="6"
                @previous="getUsers()"
                @next="getUsers()"
                @input="getUsers()"
            />
        </v-layout>
    </v-container>
</template>

<script>
import EditUserDialog from './edit-user/Dialog';
import DeleteUserDialog from './delete-user/Dialog';
import EditDialogMixin from '../../mixins/views/users/editDialog';
import DeleteDialogMixin from '../../mixins/views/users/deleteDialog';
import QueryUtils from '../../utils/query';
import IndexQueries from '../../utils/requests/dataIndex';
import UserRequests from '../../utils/requests/user';
import _ from 'lodash';
import createStorage from '../../services/LocalStorage';

const ticketStorage = createStorage('appmixer-bo-users');

export default {
    name: 'Users',

    metaInfo() {
        return {
            title: 'Appmixer Backoffice - Users'
        };
    },

    filters: {
        formatScope(scopeArray) {
            return scopeArray.join(', ');
        }
    },

    components: {
        DeleteUserDialog,
        EditUserDialog
    },

    mixins: [
        EditDialogMixin,
        DeleteDialogMixin
    ],

    data() {
        return {
            headers: [
                {
                    sortable: false,
                    text: 'User ID',
                    value: 'id'
                },
                {
                    sortable: false,
                    text: 'Username',
                    value: 'username'
                },
                {
                    sortable: false,
                    text: 'Email',
                    value: 'email'
                },
                {
                    sortable: false,
                    text: 'Created',
                    value: 'btime'
                },
                {
                    sortable: false,
                    text: 'Scope',
                    value: 'scope'
                },
                {
                    sortable: false,
                    text: 'Vendor',
                    value: 'vendor'
                },
                {
                    sortable: false,
                    text: 'Actions',
                    value: 'actions'
                }
            ],
            users: [],
            usersRemoveStatus: {},
            total: null,
            search: {
                page: 1,
                pageSize: QueryUtils.DEFAULT_PAGE_SIZE,
                loading: false,
                searchString: { old: '', current: '' },
                searchBy: {
                    value: 'email',
                    options: [
                        { text: 'Search by User Email', value: 'email' },
                        { text: 'Search by Username', value: 'username' },
                        { text: 'Search by Scope', value: 'scope' },
                        { text: 'Search by Connector/Component usage', value: 'module' }
                    ]
                }
            },
            debouncedRefreshUsers: _.debounce(this.refreshUsers, 700)
        };
    },

    computed: {
        currentUser() {
            return this.$store.getters['user/user'];
        },

        paginatorLength() {
            return this.total ? Math.ceil(this.total / this.search.pageSize) : 0;
        },

        displayUsers() {
            return this.users.map(user => {
                const removalStatus = this.usersRemoveStatus[user.id] ||
                      { inProgress: false, progress: { stepsTotal: 0, stepsDone: 0 } };

                const userRemovalStatus = {
                    loading: removalStatus.inProgress,
                    progress: removalStatus.progress
                };

                return {
                    ...user,
                    removalStatus: userRemovalStatus
                };
            });
        }
    },

    watch: {
        'search.searchString.current': 'debouncedRefreshUsers'
    },

    created() {
        const componentQuery = this.$route.query['module-query'];
        const usernameQuery = this.$route.query['username-query'];
        if (componentQuery) {
            this.search.searchBy.value = 'module';
            this.search.searchString.current = componentQuery;
        }
        if (usernameQuery) {
            this.search.searchBy.value = 'username';
            this.search.searchString.current = usernameQuery;
        }
        this.refreshUsers();
    },

    methods: {
        async refreshUsers() {
            this.search.page = 1;
            await Promise.all([
                this.getUsers(),
                this.getUsersCount()
            ]);

            this.checkRemovalsInProgress();
        },

        checkRemovalsInProgress() {
            this.users.forEach(user => {
                const ticket = ticketStorage.get(user.id);
                const { inProgress } = this.usersRemoveStatus[user.id] || {};

                if (ticket && !inProgress) {
                    const poll = this.createPoll(user.id, ticket);
                    poll.onStatus(status => {
                        this.$set(this.usersRemoveStatus, user.id, {
                            inProgress: true,
                            progress: { stepsDone: status.stepsDone, stepsTotal: status.stepsTotal }
                        });
                    });

                    poll.start();

                    poll.waitForCompletion().then(() => {
                    }).catch((err) => {
                        if (_.get(err, 'response.status') !== 404) {
                            this.deleteDialog.errorAlert.controller.showAlert(
                                'error', `An error occurred while removing ${user.email}. Please try again.`);
                        }
                    }).finally(() => {
                        ticketStorage.remove(user.id);
                        this.$set(this.usersRemoveStatus, user.id, {
                            inProgress: false,
                            progress: { stepsTotal: 0, stepsDone: 0 }
                        });
                    });
                }
            });
        },

        async getComponentQueryIds(query, { limit, offset }) {
            const [component, type] = query.split(':');

            const indexQuery = QueryUtils.getComponentUsageQuery(component, '$userId', {
                ...(type === 'running' ? { stage: 'running' } : {}),
                limit,
                offset
            });

            const result = await IndexQueries.queryComponentUsage(indexQuery);
            return result.map(r => r._id);
        },

        async getComponentCount(query) {
            const [component, type] = query.split(':');

            const indexQuery = await QueryUtils.getComponentUsageQuery(component, '$userId', {
                ...(type === 'running' ? { stage: 'running' } : {}),
                count: true
            });

            const result = await IndexQueries.queryComponentUsage(indexQuery);
            return _.get(result, '[0].count', 0);
        },

        async getUsersCount() {
            const searchString = this.search.searchString.current;
            if (this.search.searchBy.value === 'module' && searchString.length > 0) {
                this.total = await this.getComponentCount(searchString);
            } else if (this.search.searchBy.value === 'scope' && searchString.length > 0) {
                const filter = {
                    filter: { scope: searchString }
                };
                const { count } = await UserRequests.queryUsersCount(filter);
                this.total = count;
            } else {
                const filter = {
                    pattern: searchString
                };
                const { count } = await UserRequests.queryUsersCount(filter);
                this.total = count;
            }
        },

        async getUsers() {
            this.search.loading = true;
            const searchString = this.search.searchString.current;

            let users;

            if (this.search.searchBy.value === 'module' && searchString.length > 0) {
                const { limit, offset } = QueryUtils.paginationToLimitOffset(this.search.page, this.search.pageSize);
                try {
                    const userIds = await this.getComponentQueryIds(searchString, { limit, offset });
                    if (userIds.length > 0) {
                        const filter = {
                            userIds: userIds.join(','),
                            sort: { btime: 'desc' }
                        };
                        users = await UserRequests.queryUsers(filter);
                    } else {
                        users = [];
                    }
                } catch (e) {}
            } else if (this.search.searchBy.value === 'scope' && searchString.length > 0) {
                const filter = {
                    query: {
                        filter: { scope: searchString },
                        sort: { created: 'desc' },
                        page: this.search.page,
                        pageSize: this.search.pageSize
                    }
                };
                try {
                    users = await UserRequests.queryUsers(filter.query);
                } catch (e) {}
            } else {
                const filter = {
                    query: {
                        pattern: searchString,
                        sort: { created: 'desc' },
                        page: this.search.page,
                        pageSize: this.search.pageSize
                    }
                };
                try {
                    users = await UserRequests.queryUsers(filter.query);
                } catch (e) {}
            }

            this.users = users.map(user => {
                // Backwards compatibility vendor: If is a string, we wrap it in an array.
                // If is null, we return an empty array
                const vendor = typeof user.vendor === 'string' ? [user.vendor]
                    : (!user.vendor ? [] : user.vendor);
                return {
                    ...user,
                    vendor
                };
            });

            this.search.loading = false;
        },

        async reloadUser(userId) {
            const user = await UserRequests.queryUser(userId);
            const idx = this.users.findIndex(user => user.id === userId);
            this.$set(this.users, idx, user);
        },

        removeUserFromArray(userId) {
            const idx = this.users.findIndex(user => user.id === userId);
            this.users.splice(idx, 1);
            this.total--;
        }
    }
};
</script>

<style lang="scss" scoped>
  .action-icon{
    cursor: pointer;
  }
</style>
