<template>
    <router-view />
</template>

<script>
import ModulesMixin from '../../mixins/views/modules/modules';

export default {
    mixins: [
        ModulesMixin
    ],

    created() {
        this.loadModuleData();
    }
};
</script>
