import _ from 'lodash';

function setAsyncDataState(state, path, payload) {
    const { loading, data } = payload;
    _.set(state, `${path}.loading`, !!loading);
    if (data) {
        _.set(state, `${path}.data`, data);
    }
}

export default {

    setAvailableModulesState(state, payload) {
        setAsyncDataState(state, 'apps.available', payload);
    },

    setInstalledModulesState(state, payload) {
        setAsyncDataState(state, 'apps.installed', payload);
    },

    setAvailableComponentsState(state, payload) {
        setAsyncDataState(state, 'components.available', payload);
    },

    setInstalledComponentsState(state, payload) {
        setAsyncDataState(state, 'components.installed', payload);
    }
};
