<template>
    <v-navigation-drawer
        id="app-drawer"
        v-model="inputValue"
        app
        dark
        floating
        persistent
        mobile-break-point="991"
        width="260"
    >
        <v-img
            :src="image"
            :gradient="sidebarOverlayGradiant"
            height="100%"
        >
            <v-layout
                class="fill-height"
                tag="v-list"
                column
            >
                <v-list-tile avatar>
                    <v-list-tile-avatar
                        color="white"
                    >
                        <v-img
                            :src="logo"
                            height="34"
                            contain
                        />
                    </v-list-tile-avatar>
                    <v-list-tile-title class="title">
                        Appmixer
                    </v-list-tile-title>
                </v-list-tile>
                <v-divider />
                <v-list-tile
                    v-if="responsive"
                >
                    <v-text-field
                        class="purple-input search-input"
                        label="Search..."
                        color="purple"
                    />
                </v-list-tile>
                <template
                    v-for="(link, i) in links"
                >
                    <template v-if="link.children">
                        <v-list-group
                            :key="i"
                            class="v-list-item"
                            prepend-icon="mdi-animation"
                            value="true"
                        >
                            <template #activator>
                                <v-list-tile>
                                    <v-list-tile-title>{{ link.text }}</v-list-tile-title>
                                </v-list-tile>
                            </template>

                            <v-list-tile
                                v-for="(child, j) in link.children"
                                :key="`${i}-${j}`"
                                :to="child.to"
                                :active-class="color"
                                avatar
                                class="v-list-item"
                            >
                                <v-list-tile-title
                                    v-text="child.text"
                                />
                            </v-list-tile>
                        </v-list-group>
                    </template>

                    <template v-else>
                        <v-list-tile
                            :key="i"
                            :to="link.to"
                            :active-class="color"
                            avatar
                            class="v-list-item"
                        >
                            <v-list-tile-action>
                                <v-icon>{{ link.icon }}</v-icon>
                            </v-list-tile-action>

                            <v-list-tile-title
                                v-text="link.text"
                            />
                        </v-list-tile>
                    </template>
                </template>
            </v-layout>
        </v-img>
    </v-navigation-drawer>
</template>

<script>
// Utilities
import {
    mapMutations,
    mapState
} from 'vuex';

export default {
    data: () => ({
        logo: '/img/favicon.ico',
        links: [
            {
                to: '/dashboard/modules',
                icon: 'mdi-animation',
                text: 'Connectors'
            },
            {
                to: '/dashboard/service-config',
                icon: 'mdi-settings',
                text: 'Configuration'
            },
            {
                to: '/dashboard/flows',
                icon: 'mdi-sitemap',
                text: 'Flows'
            },
            {
                to: '/dashboard/users',
                icon: 'mdi-account',
                text: 'Users'
            },
            {
                to: '/dashboard/insights',
                icon: 'mdi-view-dashboard',
                text: 'Insights'
            },
            {
                to: '/dashboard/acl',
                icon: 'mdi-shield',
                text: 'ACL'
            },
            {
                to: '/dashboard/quotas',
                icon: 'mdi-bell',
                text: 'Quotas'
            },
            {
                to: '/dashboard/public-files',
                icon: 'mdi-file-multiple',
                text: 'Public Files'
            },
            {
                to: '/dashboard/system-config',
                icon: 'mdi-laptop',
                text: 'System'
            }
        ],
        responsive: false
    }),
    computed: {
        ...mapState('app', ['image', 'color']),
        inputValue: {
            get() {
                return this.$store.state.app.drawer;
            },
            set(val) {
                this.setDrawer(val);
            }
        },
        items() {
            return this.$t('Layout.View.items');
        },
        sidebarOverlayGradiant() {
            return `${this.$store.state.app.sidebarBackgroundColor}, ${this.$store.state.app.sidebarBackgroundColor}`;
        }
    },
    mounted() {
        this.onResponsiveInverted();
        window.addEventListener('resize', this.onResponsiveInverted);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResponsiveInverted);
    },
    methods: {
        ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
        onResponsiveInverted() {
            if (window.innerWidth < 991) {
                this.responsive = true;
            } else {
                this.responsive = false;
            }
        }
    }
};
</script>

<style lang="scss">
#app-drawer {
  .v-list__tile {
    border-radius: 4px;

    &--buy {
      margin-top: auto;
      margin-bottom: 17px;
    }
  }

  .v-image__image--contain {
    top: 6px;
    height: 60%;
  }

  .search-input {
    margin-bottom: 30px !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  div.v-responsive.v-image > div.v-responsive__content {
    overflow-y: auto;
  }
}
</style>
