<template>
    <div>Hi</div>
</template>

<script>
export default {
    name: 'FileDragDrop'
};
</script>

<style scoped>

</style>
