import QueryUtils from '../query';
import appmixer from '../../plugins/appmixer';

export default {
    queryConfigValues(query = {}) {
        query = QueryUtils.processQuery(query);
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/config`,
            method: 'GET',
            params: query
        });
    },

    createConfig(key, value) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/config`,
            method: 'POST',
            data: {
                key,
                value
            }
        });
    },

    deleteConfig(key) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/config/${key}`,
            method: 'DELETE'
        });
    }
};
