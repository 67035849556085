<template>
    <div>
        <v-card-text>
            Scope:
            <v-text-field
                v-model="role"
                :error="submitted && !$v.role.required"
                :hide-details="!submitted || $v.role.required"
                error-messages="Scope is required"
                class="mb-3"
                type="text"
            />

            Resource:
            <v-layout class="mb-3">
                <multiselect
                    v-model="resource"
                    :options="resources"
                    :multiple="false"
                    :searchable="false"
                    :allow-empty="false"
                    :show-labels="false"
                    placeholder="Select resource"
                    @select="item => $emit('getAttributes', item)"
                />
                <div
                    v-if="submitted && !$v.resource.required"
                    class="invalid-feedback"
                >
                    Resource is required
                </div>
            </v-layout>

            Action:
            <v-layout class="mb-3">
                <multiselect
                    v-model="action"
                    :options="actions"
                    :multiple="true"
                    :searchable="false"
                    :show-labels="false"
                    :max-height="200"
                    :open-direction="'above'"
                    placeholder="Select action"
                />
            </v-layout>

            <div
                v-if="submitted && !$v.action.required"
                class="invalid-feedback mb-3"
            >
                Action is required
            </div>

            Attributes:
            <v-layout>
                <multiselect
                    v-model="attributes"
                    :options="attributeOptions"
                    :multiple="attributesType === 'multiselect'"
                    :taggable="attributesType === 'multiselect'"
                    :searchable="attributesType === 'multiselect'"
                    :allow-empty="attributesType === 'multiselect'"
                    :show-labels="false"
                    tag-placeholder="Add this as new attribute"
                    placeholder="Select attributes"
                    @tag="addAttribute"
                />
            </v-layout>

            <div
                v-if="attribute"
                class="tooltip"
            >
                {{ attribute.tooltip }}
            </div>

            <div
                v-if="submitted && !$v.attributes.required"
                class="invalid-feedback"
            >
                Attributes is required
            </div>
        </v-card-text>

        <v-card-actions class="am-update-acl-dialog__buttons">
            <v-btn
                color="primary"
                text
                @click.stop="emitSave"
            >
                Save
            </v-btn>
            <v-btn
                color="grey"
                text
                @click.stop="$emit('cancel')"
            >
                Cancel
            </v-btn>
        </v-card-actions>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'RoutesForm',

    validations: {
        role: { required },
        resource: { required },
        action: { required },
        attributes: { required }
    },

    components: {
        Multiselect
    },

    props: {
        initialValues: {
            type: Object,
            default: null
        },
        resources: {
            type: Array,
            required: true
        },

        actions: {
            type: Array,
            required: true
        },

        aclAttributes: {
            type: Array,
            required: true
        },

        attributesType: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            role: '',
            resource: '',
            action: [],
            attributes: [],

            attribute: null,

            submitted: false
        };
    },

    computed: {
        attributeOptions() {
            return this.aclAttributes.map(a => a.value);
        }
    },

    created() {
        if (this.initialValues) {
            this.initForm(this.initialValues);
        }
    },

    methods: {
        initForm(val) {
            this.role = val.role;
            this.resource = val.resource;
            this.action = val.action;
            this.attributes = val.attributes;
        },

        addAttribute(attribute) {
            this.attributes = [...this.attributes, attribute];
        },

        emitSave() {
            this.submitted = true;

            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const payload = {
                role: this.role,
                resource: this.resource,
                action: this.action,
                attributes: this.attributes
            };

            this.$emit('save', payload);
        }
    }
};
</script>

<style scoped>
.invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.tooltip {
    width: 100%;
    margin-top: .25rem;
    font-size: 90%;
    color: #4caf50;
}
</style>
