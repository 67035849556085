import { VDialog } from 'vuetify/lib';
import ClickOutside from '../directives/click-outside';

export default {
    extends: VDialog,
    name: 'VDialog',
    directives: {
        ClickOutside
    }
};
