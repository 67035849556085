import QueryUtils from '../query';
import appmixer from '../../plugins/appmixer';

export default {

    queryAuthConfigs(query = {}) {
        query = QueryUtils.processQuery(query);
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/service-config`,
            method: 'GET',
            params: query
        });
    },

    getAuthConfig(serviceId) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/service-config/${serviceId}`,
            method: 'GET'
        });
    },

    validateConfig(serviceId) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/service-config/status/${serviceId}`,
            method: 'GET'
        });
    },

    updateAuthConfig(serviceId, payload) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/service-config/${serviceId}`,
            method: 'PUT',
            data: payload
        });
    },

    deleteAuthConfig(serviceId) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/service-config/${serviceId}`,
            method: 'DELETE'
        });
    }
};
