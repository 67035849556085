<template>
    <input
        type="file"
        class="am-input-file__input"
        @change="fileChanged"
    >
</template>

<script>
export default {
    name: 'FileSelect',

    methods: {
        fileChanged(event) {
            const file = event.target.files[0];
            this.$emit('update:value', file);
        }
    }
};
</script>

<style scoped>

</style>
