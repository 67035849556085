<template>
    <confirm-dialog
        :show="show"
        @cancel="$emit('cancel')"
    >
        <template #header>
            Confirm action
        </template>

        <template #body>
            Are you sure you want to delete this user? This action cannot be reverted.
        </template>

        <template #action-buttons>
            <div
                v-if="status.loading"
                class="delete-user-dialog__loader-wrapper"
            >
                <v-progress-linear
                    v-if="status.progress.stepsTotal === 0"
                    :indeterminate="true"
                    color="primary"
                />

                <v-progress-linear
                    v-else
                    :value="loaderProgress(status.progress)"
                    color="primary"
                />

                <div v-if="status.progress.stepsTotal !== 0 && status.progress.stepsDone === 0">
                    Stopping flows...
                </div>

                <div v-if="status.progress.stepsDone === 1">
                    Removing data...
                </div>
            </div>
            <template v-else>
                <v-btn
                    color="primary"
                    text
                    @click.stop="confirm"
                >
                    Confirm
                </v-btn>
                <v-btn
                    color="grey"
                    text
                    @click.stop="cancel"
                >
                    Cancel
                </v-btn>
            </template>
        </template>
    </confirm-dialog>
</template>

<script>
import ConfirmDialog from '../../../components/common/ConfirmDialog';
export default {
    name: 'DeleteUserDialog',

    components: {
        ConfirmDialog
    },

    props: {
        show: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object,
            default: () => {}
        },
        status: {
            type: Object,
            default: () => ({
                loading: false,
                progress: { stepsDone: 0, stepsTotal: 0 }
            })
        }
    },

    methods: {
        confirm() {
            this.$emit('confirm', this.user);
        },

        cancel() {
            this.$emit('cancel');
        },

        loaderProgress(progress) {
            return progress.stepsTotal ? (progress.stepsDone / progress.stepsTotal) * 100 : 0;
        }
    }
};
</script>

<style lang="scss" scoped>
  .delete-user-dialog {
    &__loader-wrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      font-size: 12px;
      font-weight: bold;
    }
  }
</style>
