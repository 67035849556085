import { VDataTable, VProgressCircular } from 'vuetify/lib';
export default {
    extends: VDataTable,
    name: 'VDataTable',
    props: {
        loadingData: { type: Boolean, default: false }
    },
    methods: {
        genItems() {
            if (this.loadingData) {
                const loader = this.$createElement(VProgressCircular, {
                    props: {
                        size: 50,
                        indeterminate: 'indeterminate',
                        color: 'primary'
                    }
                });
                const td = this.$createElement('td', {
                    class: {
                        'py-5': true,
                        'text-xs-center': typeof content === 'string'
                    },
                    attrs: { colspan: this.headerColumns }
                },
                [this.$createElement('div', {
                    'class': 'text-xs-center',
                    style: 'width: 100%'
                }, [loader])]
                );

                const tr = this.$createElement('tr', [td]);

                return [tr];
            }
            if (!this.itemsLength && !this.items.length) {
                const noData = this.$slots['no-data'] || this.$vuetify.t(this.noDataText);
                return [this.genEmptyItems(noData)];
            }

            if (!this.filteredItems.length) {
                const noResults = this.$slots['no-results'] || this.$vuetify.t(this.noResultsText);
                return [this.genEmptyItems(noResults)];
            }

            return this.genFilteredItems();
        }
    }
};
