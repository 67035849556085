<template>
    <v-dialog
        ref="dialog"
        :value="show"
        width="700"
        @input="$emit('close')"
    >
        <v-card>
            <v-card-title class="font-weight-bold">
                <slot />
            </v-card-title>

            <v-form
                ref="form"
                lazy-validation
            >
                <v-card-text>
                    <v-text-field
                        v-model="value.name"
                        :rules="form.name.rules"
                        label="Service"
                        required
                    />

                    <div class="mt-3">
                        <div class="am-add-quota-dialog__definition-label">
                            Definition
                            <v-progress-circular
                                v-if="testResult.loading"
                                :size="15"
                                color="primary"
                                indeterminate
                            />
                        </div>

                        <div
                            v-if="testResult.message"
                            :class="[ testResult.type === 'success' ? 'text-success' : 'text-danger' ]"
                        >
                            {{ testResult.message }}
                        </div>
                    </div>

                    <common-editor-js
                        ref="editor"
                        v-model="value.source"
                    />
                </v-card-text>

                <v-card-actions>
                    <v-btn
                        color="primary"
                        text
                        @click="test"
                    >
                        Test
                    </v-btn>

                    <div
                        v-if="loading"
                        class="am-edit-quota-dialog__loader-wrapper"
                    >
                        <v-progress-circular
                            :size="30"
                            color="primary"
                            indeterminate
                        />
                    </div>

                    <template v-else>
                        <v-btn
                            :disabled="false"
                            color="primary"
                            text
                            @click="save"
                        >
                            Save
                        </v-btn>

                        <v-btn
                            color="grey"
                            text
                            @click="close"
                        >
                            Cancel
                        </v-btn>
                    </template>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'UpsertQuotaDialog',

    props: {
        show: {
            type: Boolean,
            default: false
        },

        loading: {
            type: Boolean,
            default: false
        },

        value: {
            type: Object,
            default: () => ({
                name: '',
                source: ''
            })
        },
        testResult: {
            type: Object,
            default: () => ({ loading: false, type: null, message: null })
        }
    },

    data() {
        return {
            form: {
                name: {
                    rules: [
                        v => !!v || 'Name is required'
                    ]
                }
            }
        };
    },

    mounted() {
        // This is a hack to refresh the editor when the modal transition ends, so is rendered correctly
        const dialog = this.$refs.dialog.$children[0];
        dialog.$el.addEventListener('transitionend', () => {
            this.$refs.editor.refresh();
        });
    },

    methods: {
        save() {
            const validation = this.$refs.form.validate();
            if (validation) {
                const payload = {
                    name: this.value.name,
                    source: this.value.source
                };
                this.$refs.form.resetValidation();
                this.$emit('quota:upsert', payload);
            }
        },

        test() {
            this.$emit('quota:test', this.value.source);
        },

        close() {
            this.$refs.form.resetValidation();
            this.$emit('close');
        }
    }
};
</script>

<style lang="scss" scoped>
  @import "~codemirror/addon/lint/lint.css";
  .am-add-quota-dialog {
    &__definition-label {
      font-size: 14px;
      color: #797979;
    }
  }

</style>
