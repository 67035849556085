export default {
    methods: {
        createAlertProperties() {
            return {
                show: false,
                type: 'success',
                message: ''
            };
        },
        createAlertController(alertProps) {
            return {
                showAlert(type, message) {
                    alertProps.show = true;
                    alertProps.type = type;
                    alertProps.message = message;
                },

                closeAlert() {
                    alertProps.show = false;
                }
            };
        },

        createAlert() {
            const properties = {
                value: false,
                type: 'success',
                message: ''
            };

            const controller = {
                showAlert(type, message) {
                    properties.value = true;
                    properties.type = type;
                    properties.message = message;
                },

                closeAlert() {
                    properties.value = false;
                }
            };

            return {
                properties,
                controller
            };
        }
    }
};
