<template>
    <v-container
        fluid
        grid-list-xl
    >
        <v-layout justify-end>
            <v-btn
                color="primary"
                text
                @click="add"
            >
                Add
            </v-btn>
        </v-layout>

        <v-layout
            justify-center
            wrap
        >
            <confirm-dialog
                ref="config-details"
                :show="dialogs.details.show"
            >
                <div slot="body">
                    <div class="text-bold mb-3">
                        Key
                    </div>
                    <div>{{ dialogs.details.key }}</div>
                    <div class="text-bold mt-5 mb-3">
                        Value
                    </div>
                    <div class="system-config-view__detail-value">
                        {{ dialogs.details.value }}
                    </div>
                </div>

                <div slot="action-buttons">
                    <v-btn
                        color="primary"
                        text
                        @click.stop="closeDetails"
                    >
                        Close
                    </v-btn>
                </div>
            </confirm-dialog>

            <confirm-dialog-loader
                ref="confirm-remove"
                :show="dialogs.remove.show"
                :loading="dialogs.remove.loading"
                @confirm="confirmRemove"
                @cancel="closeRemoveDialog"
            >
                <div slot="header">
                    Remove?
                </div>

                <div slot="body">
                    Are you sure you want to remove the entry for {{ dialogs.remove.key }}?
                </div>
            </confirm-dialog-loader>

            <v-flex xs12>
                <v-data-table
                    :headers="headers"
                    :items="configValues.data"
                    :loading-data="configValues.loading"
                    hide-actions
                >
                    <template
                        slot="headerCell"
                        slot-scope="{ header }"
                    >
                        <span
                            class="subheading font-weight-light text-primary text--darken-3"
                            v-text="header.text"
                        />
                    </template>

                    <template
                        slot="items"
                        slot-scope="{ item }"
                    >
                        <template v-if="item.editing">
                            <td>
                                <v-text-field
                                    v-model="forms.edit.key"
                                    placeholder="Key"
                                    type="text"
                                />

                                <div
                                    v-if="!$v.forms.edit.key.required"
                                    class="invalid-feedback"
                                >
                                    Key is required
                                </div>

                                <div
                                    v-if="!$v.forms.edit.key.isUnique"
                                    class="invalid-feedback"
                                >
                                    Key already exists
                                </div>
                            </td>

                            <td>
                                <v-text-field
                                    v-model="forms.edit.value"
                                    placeholder="Value"
                                    type="text"
                                />

                                <div
                                    v-if="!$v.forms.edit.value.required"
                                    class="invalid-feedback"
                                >
                                    Value is required
                                </div>
                            </td>

                            <td>
                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon
                                            class="mr-3"
                                            v-on="on"
                                            @click="confirmEdit()"
                                        >
                                            mdi-check
                                        </v-icon>
                                    </template>
                                    <span class="text-white">Confirm</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon
                                            v-on="on"
                                            @click="cancelEdit(item)"
                                        >
                                            mdi-close
                                        </v-icon>
                                    </template>
                                    <span class="text-white">Cancel</span>
                                </v-tooltip>
                            </td>
                        </template>

                        <template v-else-if="item.creating">
                            <td>
                                <v-text-field
                                    v-model="forms.create.key"
                                    placeholder="Key"
                                    type="text"
                                />

                                <div
                                    v-if="!$v.forms.create.key.required"
                                    class="invalid-feedback"
                                >
                                    Key is required
                                </div>

                                <div
                                    v-if="!$v.forms.create.key.isUnique"
                                    class="invalid-feedback"
                                >
                                    Key already exists
                                </div>
                            </td>

                            <td>
                                <v-text-field
                                    v-model="forms.create.value"
                                    placeholder="Value"
                                    type="text"
                                />

                                <div
                                    v-if="!$v.forms.create.value.required"
                                    class="invalid-feedback"
                                >
                                    Value is required
                                </div>
                            </td>

                            <td>
                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon
                                            class="mr-3"
                                            v-on="on"
                                            @click="confirmCreate()"
                                        >
                                            mdi-check
                                        </v-icon>
                                    </template>
                                    <span class="text-white">Confirm</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon
                                            v-on="on"
                                            @click="cancelCreate()"
                                        >
                                            mdi-close
                                        </v-icon>
                                    </template>
                                    <span class="text-white">Cancel</span>
                                </v-tooltip>
                            </td>
                        </template>

                        <template v-else>
                            <td>{{ item.key }}</td>

                            <td class="system-config-view__table-value-column">
                                {{ item.value }}
                            </td>

                            <td>
                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon
                                            class="mr-3"
                                            v-on="on"
                                            @click="openDetails(item)"
                                        >
                                            mdi-eye
                                        </v-icon>
                                    </template>
                                    <span class="text-white">View</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon
                                            class="mr-3"
                                            v-on="on"
                                            @click="edit(item)"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span class="text-white">Edit</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon
                                            v-on="on"
                                            @click="openRemoveDialog(item.key)"
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                    <span class="text-white">Delete</span>
                                </v-tooltip>
                            </td>
                        </template>
                    </template>
                </v-data-table>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import ConfigRequests from '../../utils/requests/config';
import ConfirmDialogLoader from '../../components/common/ConfirmDialog/WithLoader';
import { required } from 'vuelidate/lib/validators';
import ConfirmDialog from '../../components/common/ConfirmDialog';

export default {
    name: 'SystemConfigView',

    validations: {
        forms: {
            create: {
                key: {
                    required,
                    isUnique(value) {
                        return value === '' || !(this.configValues.data.find(i => i.key === value));
                    }
                },
                value: {
                    required
                }
            },
            edit: {
                key: {
                    required,
                    isUnique(value) {
                        return value !== '' || !(this.configValues.data.find(i => i.key === value));
                    }
                },
                value: {
                    required
                }
            }
        }
    },

    components: {
        ConfirmDialog,
        ConfirmDialogLoader
    },

    data() {
        return {
            dialogs: {
                details: {
                    show: false,
                    key: '',
                    value: ''
                },
                remove: {
                    show: false,
                    loading: false,
                    key: ''
                }
            },
            forms: {
                create: {
                    key: '',
                    value: ''
                },
                edit: {
                    key: '',
                    value: ''
                }
            },
            configValues: {
                loading: false,
                data: []
            },
            query: {},
            headers: [
                {
                    sortable: false,
                    text: 'Key',
                    value: 'type'
                },
                {
                    sortable: false,
                    text: 'Value',
                    value: 'value'
                }
            ]
        };
    },

    created() {
        this.getConfigValues();
    },

    methods: {
        async getConfigValues(query) {
            this.configValues.loading = true;
            try {
                this.configValues.data = await ConfigRequests.queryConfigValues(query);
            } finally {
                this.configValues.loading = false;
            }
        },

        openDetails(item) {
            this.dialogs.details.show = true;
            this.dialogs.details.key = item.key;
            this.dialogs.details.value = item.value;
        },

        closeDetails() {
            this.dialogs.details.show = false;
            this.dialogs.details.key = '';
            this.dialogs.details.value = '';
        },

        add() {
            const entry = { key: '', value: '', creating: true };
            this.configValues.data = [...this.configValues.data, entry];
        },

        async confirmCreate() {
            if (!this.$v.forms.create.$invalid) {
                await ConfigRequests.createConfig(this.forms.create.key, this.forms.create.value);
                this.getConfigValues();

                this.forms.create.key = '';
                this.forms.create.value = '';
            }
        },

        cancelCreate() {
            const length = this.configValues.data.length;
            this.configValues.data = this.configValues.data.slice(0, length - 1);
            this.forms.create.key = '';
            this.forms.create.value = '';
        },

        edit(item) {
            const idx = this.configValues.data.findIndex(i => i.key === item.key);
            this.$set(this.configValues.data, idx, { ...item, editing: true });
            this.forms.edit.oldKey = item.key;
            this.forms.edit.key = item.key;
            this.forms.edit.value = item.value;
        },

        async confirmEdit() {
            if (!this.$v.forms.edit.$invalid) {
                await ConfigRequests.deleteConfig(this.forms.edit.oldKey);
                await ConfigRequests.createConfig(this.forms.edit.key, this.forms.edit.value);
                this.getConfigValues();

                this.forms.edit.key = '';
                this.forms.edit.value = '';
            }
        },

        cancelEdit(item) {
            item.editing = false;
            this.forms.edit.key = '';
            this.forms.edit.value = '';
        },

        openRemoveDialog(key) {
            this.dialogs.remove.show = true;
            this.dialogs.remove.key = key;
        },

        closeRemoveDialog() {
            this.dialogs.remove.show = false;
            this.dialogs.remove.key = '';
        },

        async confirmRemove() {
            this.dialogs.remove.loading = true;
            await ConfigRequests.deleteConfig(this.dialogs.remove.key);
            this.dialogs.remove.loading = false;
            this.dialogs.remove.show = false;

            this.getConfigValues();
        }
    }
};
</script>

<style lang="scss" scoped>

  .system-config-view {
    &__detail-value {
      overflow-wrap: break-word;
    }

    &__table-value-column {
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

</style>
