import appmixer from '../../plugins/appmixer';

export default {
    getStatus() {
        return appmixer.api.request({
            url: appmixer.api.baseUrl,
            method: 'GET'
        });
    }
};
