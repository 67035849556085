import appmixer from '../../plugins/appmixer';

export default {

    getEngineInfo() {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}`,
            method: 'GET'
        });
    }
};
