export default function createStorage(prefix) {
    function createKey(key) {
        return prefix ? `${prefix}-${key}` : key;
    }

    return {
        get(key) {
            const storageKey = createKey(key);
            return localStorage.getItem(storageKey);
        },

        set(key, value) {
            const storageKey = createKey(key);
            localStorage.setItem(storageKey, value);
        },

        remove(key) {
            const storageKey = createKey(key);
            localStorage.removeItem(storageKey);
        }
    };
}
