<template>
    <v-dialog
        :value="show"
        width="400"
        @input="$emit('cancel')"
    >
        <v-card>
            <v-card-title class="am-confirm-dialog__title">
                <slot name="header">
                    {{ title }}
                </slot>
            </v-card-title>

            <v-card-text>
                <slot name="body">
                    {{ body }}
                </slot>
            </v-card-text>

            <v-card-actions class="am-confirm-dialog__buttons">
                <slot name="action-buttons">
                    <v-btn
                        color="primary"
                        text
                        @click.stop="confirm"
                    >
                        Confirm
                    </v-btn>
                    <v-btn
                        color="grey"
                        text
                        @click.stop="$emit('cancel')"
                    >
                        Cancel
                    </v-btn>
                </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ConfirmDialog',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        body: {
            type: String,
            default: ''
        },
        // eslint-disable-next-line vue/require-default-prop
        callback: {
            type: Function
        }
    },

    methods: {
        confirm() {
            if (this.callback) {
                this.callback();
            } else {
                this.$emit('confirm');
            }
        }
    }
};
</script>

<style lang="scss">
.am-confirm-dialog__title {
    font-weight: bold;
}
</style>
