<template>
    <v-container
        fluid
        grid-list-xl
    >
        <v-layout
            justify-center
            wrap
        >
            <new-service-dialog
                :show="createDialog.show"
                @create="createService"
                @cancel="hideCreateDialog"
            />

            <confirm-dialog
                :show="confirmDeleteDialog.show"
                @confirm="removeConfig"
                @cancel="hideConfirmDeleteDialog"
            >
                <template #header>
                    Confirm action
                </template>
                <template #body>
                    Are you sure you want to delete this configuration?
                </template>
            </confirm-dialog>

            <v-flex xs12>
                <v-layout align-center>
                    <v-flex md6>
                        <v-text-field
                            v-model="query.pattern"
                            label="Search Connector Configurations"
                            @keyup="debouncedQueryConfig"
                        />
                    </v-flex>

                    <v-flex md6>
                        <v-layout justify-end>
                            <v-btn
                                color="primary"
                                text
                                @click="showCreateDialog"
                            >
                                Add Configuration
                            </v-btn>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex xs12>
                <material-card
                    title="Connector Configuration"
                    color="primary"
                >
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :loading-data="loading"
                        no-data-text="No connector configurations found"
                        hide-actions
                    >
                        <template
                            slot="headerCell"
                            slot-scope="{ header }"
                        >
                            <span
                                class="subheading font-weight-light text-primary text--darken-3"
                                v-text="header.text"
                            />
                        </template>

                        <template
                            slot="items"
                            slot-scope="{ item }"
                        >
                            <template v-if="!editStatus[item.serviceId]">
                                <td>{{ item.serviceId }}</td>
                                <td>
                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-icon
                                                class="mr-3"
                                                v-on="on"
                                                @click="editService(item)"
                                            >
                                                mdi-pencil
                                            </v-icon>
                                        </template>
                                        <span class="text-white">Edit Configuration ID</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-icon
                                                class="mr-3"
                                                v-on="on"
                                                @click="viewConfig(item)"
                                            >
                                                mdi-format-list-bulleted
                                            </v-icon>
                                        </template>
                                        <span class="text-white">Configuration Values</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-icon
                                                v-on="on"
                                                @click="showConfirmDeleteDialog(item)"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </template>
                                        <span class="text-white">Delete</span>
                                    </v-tooltip>
                                </td>
                            </template>
                            <template v-else>
                                <td>
                                    <v-text-field
                                        v-model="editInputs.serviceId"
                                        placeholder="Configuration ID"
                                        type="text"
                                    />
                                    <div
                                        v-if="editSubmitted && !$v.editInputs.serviceId.required"
                                        class="invalid-feedback"
                                    >
                                        Configuration ID is required
                                    </div>
                                    <div
                                        v-if="editSubmitted && errors.isUnique"
                                        class="invalid-feedback"
                                    >
                                        Configuration ID already exists
                                    </div>
                                </td>
                                <td>
                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-icon
                                                class="mr-3"
                                                v-on="on"
                                                @click="confirmEdit()"
                                            >
                                                mdi-check
                                            </v-icon>
                                        </template>
                                        <span class="text-white">Confirm</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-icon
                                                v-on="on"
                                                @click="cancelEdit()"
                                            >
                                                mdi-close
                                            </v-icon>
                                        </template>
                                        <span class="text-white">Cancel</span>
                                    </v-tooltip>
                                </td>
                            </template>
                        </template>
                    </v-data-table>
                </material-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import _ from 'lodash';
import AuthConfigRequests from '../../utils/requests/authConfig';
import NewServiceDialog from './NewServiceDialog';
import ConfirmDialog from '../../components/common/ConfirmDialog';
import { required } from 'vuelidate/lib/validators';
export default {
    name: 'Services',
    components: {
        ConfirmDialog,
        NewServiceDialog
    },
    validations: {
        editInputs: {
            serviceId: {
                required
            }
        }
    },
    metaInfo() {
        return {
            title: 'Appmixer Backoffice - Connectors Configuration'
        };
    },

    data() {
        return {
            headers: [
                {
                    sortable: false,
                    text: 'Configuration ID',
                    value: 'service'
                },
                {
                    sortable: false,
                    text: '',
                    value: 'config'
                }
            ],
            items: [],
            loading: false,
            query: {
                pattern: '',
                sort: 'serviceId:1'
            },
            debouncedQueryConfig: _.debounce(this.loadServiceConfig, 700),
            editing: null,
            editSubmitted: false,
            editInputs: {
                serviceId: null
            },
            createDialog: {
                show: false
            },
            confirmDeleteDialog: {
                show: false,
                item: null
            },
            errors: {
                isUnique: false
            }
        };
    },

    computed: {
        editStatus() {
            return this.items.reduce((acc, item) => {
                acc[item.serviceId] = item.serviceId === (this.editing || {}).serviceId;
                return acc;
            }, {});
        }
    },

    watch: {
        editInputs: {
            deep: true,
            handler() {
                this.editSubmitted = false;
                this.errors.isUnique = false;
            }
        }
    },

    created() {
        this.loadServiceConfig();
    },

    methods: {
        showCreateDialog() {
            this.createDialog.show = true;
        },

        hideCreateDialog() {
            this.createDialog.show = false;
        },

        showConfirmDeleteDialog(item) {
            this.confirmDeleteDialog.show = true;
            this.confirmDeleteDialog.item = item;
        },

        hideConfirmDeleteDialog() {
            this.confirmDeleteDialog.show = false;
            this.confirmDeleteDialog.item = null;
        },

        async loadServiceConfig() {
            const query = this.query;
            this.loading = true;
            try {
                this.items = await AuthConfigRequests.queryAuthConfigs(query);
            } finally {
                this.loading = false;
            }
        },

        viewConfig(item) {
            this.$router.push({ path: `/dashboard/service-config/${item.serviceId}` });
        },

        async createService(serviceId) {
            await AuthConfigRequests.updateAuthConfig(serviceId, {});
            this.$router.push({ path: `/dashboard/service-config/${serviceId}` });
        },

        editService(item) {
            this.editing = item;
            this.editInputs.serviceId = item.serviceId;
        },

        async confirmEdit() {
            this.editSubmitted = true;
            const { serviceId: oldServiceId } = this.editing;
            const { serviceId: newServiceId } = this.editInputs;
            if (newServiceId === oldServiceId) {
                this.cancelEdit();
                return;
            }
            const isUnique = await this.isUnique(newServiceId);
            if (!isUnique) {
                this.errors.isUnique = true;
            } else if (!this.$v.$invalid) {
                await AuthConfigRequests.deleteAuthConfig(oldServiceId);
                await AuthConfigRequests.updateAuthConfig(newServiceId, _.omit(this.editing, 'serviceId'));
                this.cancelEdit();
                await this.loadServiceConfig();
            }
        },

        cancelEdit() {
            this.editing = null;
            this.editInputs.serviceId = null;
        },

        async isUnique(value) {
            if (!value) {
                return true;
            }
            const result = await AuthConfigRequests.getAuthConfig(value);
            return Object.keys(result).length === 0;
        },

        async removeConfig() {
            const serviceId = this.confirmDeleteDialog.item.serviceId;
            this.hideConfirmDeleteDialog();
            await AuthConfigRequests.deleteAuthConfig(serviceId);
            await this.loadServiceConfig();
        }
    }
};
</script>

<style scoped>
  .invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    color: #dc3545;
  }
</style>
