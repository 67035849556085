export default {

    setLoading(state, loadingStatus) {
        state.loading = loadingStatus;
    },

    setError(state, error) {
        state.error = error;
    },

    setAccessToken(state, token) {
        sessionStorage.setItem('accessToken', token);
        state.accessToken = token;
    },

    unsetAccessToken(state) {
        sessionStorage.removeItem('accessToken');
        state.accessToken = null;
    },

    setUser(state, user) {
        state.user = user;
    }
};
