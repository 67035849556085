var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-end":""}},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.add}},[_vm._v(" Add ")])],1),_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('confirm-dialog',{ref:"config-details",attrs:{"show":_vm.dialogs.details.show}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"text-bold mb-3"},[_vm._v(" Key ")]),_c('div',[_vm._v(_vm._s(_vm.dialogs.details.key))]),_c('div',{staticClass:"text-bold mt-5 mb-3"},[_vm._v(" Value ")]),_c('div',{staticClass:"system-config-view__detail-value"},[_vm._v(" "+_vm._s(_vm.dialogs.details.value)+" ")])]),_c('div',{attrs:{"slot":"action-buttons"},slot:"action-buttons"},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeDetails.apply(null, arguments)}}},[_vm._v(" Close ")])],1)]),_c('confirm-dialog-loader',{ref:"confirm-remove",attrs:{"show":_vm.dialogs.remove.show,"loading":_vm.dialogs.remove.loading},on:{"confirm":_vm.confirmRemove,"cancel":_vm.closeRemoveDialog}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v(" Remove? ")]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_vm._v(" Are you sure you want to remove the entry for "+_vm._s(_vm.dialogs.remove.key)+"? ")])]),_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.configValues.data,"loading-data":_vm.configValues.loading,"hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"subheading font-weight-light text-primary text--darken-3",domProps:{"textContent":_vm._s(header.text)}})]}},{key:"items",fn:function(ref){
var item = ref.item;
return [(item.editing)?[_c('td',[_c('v-text-field',{attrs:{"placeholder":"Key","type":"text"},model:{value:(_vm.forms.edit.key),callback:function ($$v) {_vm.$set(_vm.forms.edit, "key", $$v)},expression:"forms.edit.key"}}),(!_vm.$v.forms.edit.key.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Key is required ")]):_vm._e(),(!_vm.$v.forms.edit.key.isUnique)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Key already exists ")]):_vm._e()],1),_c('td',[_c('v-text-field',{attrs:{"placeholder":"Value","type":"text"},model:{value:(_vm.forms.edit.value),callback:function ($$v) {_vm.$set(_vm.forms.edit, "value", $$v)},expression:"forms.edit.value"}}),(!_vm.$v.forms.edit.value.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Value is required ")]):_vm._e()],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3",on:{"click":function($event){return _vm.confirmEdit()}}},on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('span',{staticClass:"text-white"},[_vm._v("Confirm")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.cancelEdit(item)}}},on),[_vm._v(" mdi-close ")])]}}],null,true)},[_c('span',{staticClass:"text-white"},[_vm._v("Cancel")])])],1)]:(item.creating)?[_c('td',[_c('v-text-field',{attrs:{"placeholder":"Key","type":"text"},model:{value:(_vm.forms.create.key),callback:function ($$v) {_vm.$set(_vm.forms.create, "key", $$v)},expression:"forms.create.key"}}),(!_vm.$v.forms.create.key.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Key is required ")]):_vm._e(),(!_vm.$v.forms.create.key.isUnique)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Key already exists ")]):_vm._e()],1),_c('td',[_c('v-text-field',{attrs:{"placeholder":"Value","type":"text"},model:{value:(_vm.forms.create.value),callback:function ($$v) {_vm.$set(_vm.forms.create, "value", $$v)},expression:"forms.create.value"}}),(!_vm.$v.forms.create.value.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Value is required ")]):_vm._e()],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3",on:{"click":function($event){return _vm.confirmCreate()}}},on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('span',{staticClass:"text-white"},[_vm._v("Confirm")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.cancelCreate()}}},on),[_vm._v(" mdi-close ")])]}}],null,true)},[_c('span',{staticClass:"text-white"},[_vm._v("Cancel")])])],1)]:[_c('td',[_vm._v(_vm._s(item.key))]),_c('td',{staticClass:"system-config-view__table-value-column"},[_vm._v(" "+_vm._s(item.value)+" ")]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3",on:{"click":function($event){return _vm.openDetails(item)}}},on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',{staticClass:"text-white"},[_vm._v("View")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3",on:{"click":function($event){return _vm.edit(item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',{staticClass:"text-white"},[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.openRemoveDialog(item.key)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',{staticClass:"text-white"},[_vm._v("Delete")])])],1)]]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }