import jwt from 'jsonwebtoken';
import store from '@/store';

function isExpiredAccessToken(token) {
    const decoded = jwt.decode(token);
    if (decoded && decoded.exp) {
        const timeInSeconds = Math.ceil(new Date().getTime() / 1000);
        return decoded.exp < timeInSeconds;
    }
    return true;
}

export default {
    // Verify authentication of the route.
    authenticated(route) {
        const token = store.getters['user/accessToken'];
        if (route.meta.auth === true) {
            return token && !isExpiredAccessToken(token);
        }
        return true;
    }

};
