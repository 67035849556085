<template>
    <v-dialog
        :value="show"
        class="am-update-acl-dialog"
        width="500"
        @input="($event) => $emit('updateShow', $event)"
    >
        <v-card v-if="show">
            <v-card-title class="am-update-acl-dialog__title">
                Update ACL ({{ type }})
            </v-card-title>

            <routes-form
                v-if="type === 'routes'"
                :initial-values="rule"
                :resources="aclResources"
                :actions="actions"
                :acl-attributes="aclAttributes.options"
                :attributes-type="aclAttributes.type"
                @save="(payload) => $emit('save', payload)"
                @cancel="$emit('cancel')"
            />

            <components-form
                v-if="type === 'components'"
                :initial-values="rule"
                :resource-pattern="aclResources.pattern"
                :actions="actions"
                :acl-attributes="aclAttributes.options"
                :attributes-type="aclAttributes.type"
                @getAttributes="(payload) => $emit('getAttributes', payload)"
                @save="(payload) => $emit('save', payload)"
                @cancel="$emit('cancel')"
            />
        </v-card>
    </v-dialog>
</template>

<script>
import RoutesForm from '@/views/acl/update-dialog/RoutesForm';
import ComponentsForm from '@/views/acl/update-dialog/ComponentsForm';

export default {
    name: 'UpdateAclDialog',

    components: {
        ComponentsForm,
        RoutesForm
    },

    model: {
        prop: 'show',
        event: 'updateShow'
    },

    props: {
        show: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: ''
        },
        rule: {
            type: Object,
            default: () => {}
        },
        aclResources: {
            type: Array | Object,
            default: () => [] | {}
        },
        actions: {
            type: Array,
            default: () => []
        },
        aclAttributes: {
            type: Object,
            default: () => {}
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

.am-update-acl-dialog {
    &__title {
        font-weight: bold;
    }
}
</style>
