<template>
    <!-- eslint-disable -->
    <v-container
        fluid
        grid-list-xl
    >
        <v-layout
            justify-center
            wrap
        >
            <div
                v-text="`Public files are files that are available at the root location of your Appmixer Engine API endpoint. For example, uploading verify.html will make the file available at ${appmixerApiBaseUrl}/verify.html. This is very useful especially if you want to verify ownership of your Appmixer domain with third parties.`"
            /><br>
            <confirm-dialog-loader
                ref="confirm-overwrite"
                :show="dialogs.overwrite.show"
                :loading="dialogs.overwrite.loading"
                @confirm="confirmOverwrite"
                @cancel="closeOverwriteDialog"
            >
                <div slot="header">
                    Overwrite file?
                </div>

                <div slot="body">
                    There's already a file named {{ dialogs.overwrite.file.name }}
                    are you sure you want to overwrite it?
                </div>
            </confirm-dialog-loader>

            <confirm-dialog-loader
                ref="confirm-remove"
                :show="dialogs.remove.show"
                :loading="dialogs.remove.loading"
                @confirm="confirmRemove"
                @cancel="closeRemoveDialog"
            >
                <div slot="header">
                    Remove file?
                </div>

                <div slot="body">
                    Are you sure you want to remove {{ dialogs.remove.filename }}?
                </div>
            </confirm-dialog-loader>

            <upload-file-dialog
                ref="upload"
                :show="dialogs.upload.show"
                @upload="handleUploadEvent"
                @close="closeUploadDialog"
            />

            <v-flex xs12>
                <v-btn
                    color="primary"
                    text
                    @click="showUploadDialog"
                >
                    Upload File
                </v-btn>
            </v-flex>

            <v-flex xs12>
                <v-data-table
                    :headers="headers"
                    :items="files.data"
                    :loading-data="files.loading"
                    hide-actions
                >
                    <template
                        slot="headerCell"
                        slot-scope="{ header }"
                    >
                        <span
                            class="subheading font-weight-light text-primary text--darken-3"
                            v-text="header.text"
                        />
                    </template>

                    <template
                        slot="items"
                        slot-scope="{ item }"
                    >
                        <td>
                            <a
                                :href="item.url"
                                target="_blank"
                            >{{ item.filename }}</a>
                        </td>
                        <td>
                            <v-icon
                                class="action-icon"
                                @click="openRemoveDialog(item.filename)"
                            >
                                mdi-delete
                            </v-icon>
                        </td>
                    </template>
                </v-data-table>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import PublicFilesRequests from '../../utils/requests/publicFiles';
import UploadFileDialog from './upload-file/Dialog';
import ConfirmDialogLoader from '../../components/common/ConfirmDialog/WithLoader';
import appmixer from '@/plugins/appmixer';
export default {
    name: 'PublicFilesView',

    components: {
        ConfirmDialogLoader,
        UploadFileDialog
    },

    data() {
        return {
            dialogs: {
                upload: {
                    show: false,
                    loading: false
                },
                overwrite: {
                    show: false,
                    loading: false,
                    file: {}
                },
                remove: {
                    show: false,
                    loading: false,
                    filename: ''
                }
            },
            files: {
                loading: false,
                data: []
            },
            query: {},
            appmixerApiBaseUrl: appmixer.api.baseUrl,
            headers: [
                {
                    sortable: false,
                    text: 'Name',
                    value: 'name'
                }
            ]
        };
    },

    created() {
        this.getPublicFiles();
    },

    methods: {
        async getPublicFiles(query) {
            this.files.loading = true;
            try {
                const data = await PublicFilesRequests.queryPublicFiles(query);
                this.files.data = data.map((file) => {
                    return { ...file, url: `${appmixer.api.baseUrl}/${file.filename}` };
                });
            } finally {
                this.files.loading = false;
            }
        },

        showUploadDialog() {
            this.dialogs.upload.show = true;
        },

        closeUploadDialog() {
            this.dialogs.upload.show = false;
            this.$refs.upload.clearDropzone();
        },

        async handleUploadEvent(file) {
            this.dialogs.upload.loading = true;
            const files = await PublicFilesRequests.queryPublicFiles({ filename: file.name });
            if (files.length > 0) {
                this.dialogs.upload.show = false;
                this.dialogs.upload.loading = false;
                this.openOverwriteDialog(file);
            } else {
                await PublicFilesRequests.upload(file.name, file);
                this.dialogs.upload.loading = false;
                this.closeUploadDialog();

                this.getPublicFiles();
            }
        },

        openOverwriteDialog(file) {
            this.dialogs.overwrite.show = true;
            this.dialogs.overwrite.file = file;
        },

        closeOverwriteDialog() {
            this.dialogs.overwrite.show = false;
            this.dialogs.overwrite.file = {};
        },

        async confirmOverwrite() {
            const { file } = this.dialogs.overwrite;
            this.dialogs.overwrite.show = true;
            this.dialogs.overwrite.loading = true;
            await PublicFilesRequests.remove(file.name);
            await PublicFilesRequests.upload(file.name, file);
            this.dialogs.overwrite.show = false;
            this.dialogs.overwrite.loading = false;
            this.$refs.upload.clearDropzone();

            this.getPublicFiles();
        },

        openRemoveDialog(filename) {
            this.dialogs.remove.show = true;
            this.dialogs.remove.filename = filename;
        },

        closeRemoveDialog() {
            this.dialogs.remove.show = false;
            this.dialogs.remove.file = {};
        },

        async confirmRemove() {
            this.dialogs.remove.loading = true;
            await PublicFilesRequests.remove(this.dialogs.remove.filename);
            this.dialogs.remove.loading = false;
            this.dialogs.remove.show = false;

            this.getPublicFiles();
        }
    }
};
</script>

<style scoped>

</style>
