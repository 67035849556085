<template>
    <confirm-dialog
        :show="show"
        :title="title"
        :body="body"
        :callback="callback"
        @cancel="$emit('cancel')"
    >
        <template #header>
            <slot name="header" />
        </template>

        <template #body>
            <slot name="body" />
        </template>

        <template #action-buttons>
            <div
                v-if="loading"
                class="confirm-dialog__loader-wrapper"
            >
                <v-progress-circular
                    :size="30"
                    color="primary"
                    indeterminate
                />
            </div>

            <template v-else>
                <v-btn
                    color="primary"
                    text
                    @click.stop="confirm"
                >
                    Confirm
                </v-btn>

                <v-btn
                    color="grey"
                    text
                    @click.stop="$emit('cancel')"
                >
                    Cancel
                </v-btn>
            </template>
        </template>
    </confirm-dialog>
</template>

<script>
import ConfirmDialog from './Basic';
export default {
    name: 'ConfirmDialogLoader',

    components: {
        ConfirmDialog
    },

    extends: ConfirmDialog,

    props: {
        loading: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
  .confirm-dialog {
    &__loader-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
</style>
