import AuthConfigRequests from '@/utils/requests/authConfig';

export default {

    async validateConfig(module, installed) {
        const results = { isModuleConfigValid: true, isServiceConfigValid: true };
        if (installed) {
            const moduleId = module.replace(/\./g, ':');
            const split = moduleId.split(':');
            const moduleConfig = await AuthConfigRequests.validateConfig(moduleId);
            const moduleValidationErrors = moduleConfig.errors || [];
            if (moduleValidationErrors.length >= 1) {
                results.isModuleConfigValid = false;
            }
            if (split.length === 3) {
                const serviceId = split.slice(0, -1).join(':');
                let serviceConfig = await AuthConfigRequests.validateConfig(serviceId);
                const serviceValidationErrors = serviceConfig.errors || [];
                if (serviceValidationErrors.length >= 1) {
                    results.isServiceConfigValid = false;
                }
            }
        }
        return results;
    }
};
