/**
 * @abstract
 */
export default class AbstractProvider {
    /**
     * @abstract
     */
    getModules() {
        throw new Error('Not implemented');
    }

    /**
     * @abstract
     */
    getComponents() {
        throw new Error('Not implemented');
    }

    /**
     * @abstract
     * @param name
     */
    download(name) {
        throw new Error('Not implemented');
    }

    /**
     * @abstract
     * @param name
     */
    install(name) {
        throw new Error('Not implemented');
    }
}
