export default {
    trimNullValues(object) {
        let newObject = {};
        Object.keys(object).forEach((key) => {
            if (object[key] != null) {
                newObject[key] = object[key];
            }
        });
        return newObject;
    },

    isEmpty(object = {}) {
        return Object.keys(object).length === 0;
    }
};
