import AppsRequests from './requests/apps';

export default {
    async uploadModule(file) {
        const response = await AppsRequests.upload(file);
        const { ticket } = response;
        if (!ticket) {
            throw new Error('Invalid response from server, missing ticket.');
        }

        return { ticket };
    }
};
