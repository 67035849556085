import UserRequests from '../../../utils/requests/user';
import Poll from '../../../utils/poll';
import AlertMixin from '../../../mixins/alert';
import ConfirmDialogMixin from '../../../mixins/confirmDialog';
import createStorage from '../../../services/LocalStorage';

const ticketStorage = createStorage('appmixer-bo-users');

export default {
    mixins: [
        AlertMixin,
        ConfirmDialogMixin
    ],

    data() {
        return {
            deleteDialog: {
                errorAlert: this.createAlert(),
                dialog: this.createConfirmDialog(),
                currentUser: {},
                status: {
                    loading: false,
                    progress: {
                        stepsTotal: 0,
                        stepsDone: 0
                    }
                }
            }
        };
    },

    computed: {
        deleteDialogStatus() {
            const removalStatus = this.usersRemoveStatus[this.deleteDialog.currentUser.id] ||
                { inProgress: false, progress: { stepsTotal: 0, stepsDone: 0 } };

            return {
                loading: removalStatus.inProgress,
                progress: removalStatus.progress
            };
        }
    },

    methods: {
        deleteUser(user) {
            this.deleteDialog.currentUser = user;
            this.deleteDialog.dialog.controller.showDialog();
        },

        createPoll(userId, ticket) {
            const statusFn = async () => {
                return UserRequests.removeStatus(userId, ticket);
            };
            const successCb = (status) => {
                return status.status === 'completed' || status.status === 'cancelled';
            };
            const errorCb = (status) => {
                return status.err;
            };

            return new Poll(statusFn, successCb, errorCb, { interval: 10000 });
        },

        async removeUser(user) {
            this.$set(this.usersRemoveStatus, user.id, {
                inProgress: true,
                progress: { stepsTotal: 0, stepsDone: 0 }
            });

            let ticket;

            try {
                ticket = (await UserRequests.removeUser(user.id)).ticket;
            } catch (err) {
                this.deleteDialog.dialog.controller.closeDialog();
                const errorMsg = 'An error has ocurred. Please try again later.';
                this.deleteDialog.errorAlert.controller.showAlert('error', errorMsg);
                return;
            }

            ticketStorage.set(user.id, ticket);

            const poll = this.createPoll(user.id, ticket);
            poll.onStatus(status => {
                this.$set(this.usersRemoveStatus, user.id, {
                    inProgress: true,
                    progress: { stepsDone: status.stepsDone, stepsTotal: status.stepsTotal }
                });
            });

            poll.start();

            try {
                await poll.waitForCompletion();
                this.removeUserFromArray(user.id);
                this.deleteDialog.status.loading = false;
            } catch (err) {
                const errorMsg = 'An error has ocurred. Please try again later.';
                this.deleteDialog.errorAlert.controller.showAlert('error', errorMsg);
            }

            ticketStorage.remove(user.id);

            this.$set(this.usersRemoveStatus, user.id, {
                inProgress: false,
                progress: { stepsTotal: 0, stepsDone: 0 }
            });
            this.deleteDialog.dialog.controller.closeDialog();
        }
    }
};
