import { capitalize } from '../utils/string';

export default {

    methods: {

        loadRelationships(models, relationships) {
            relationships.forEach(relation => {
                this['load' + capitalize(relation) + 'Relationship'](models);
            });
        }
    }
};
