<template>
    <v-data-table
        :headers="headers"
        :items="rules"
        :loading-data="false"
        :no-data-text="`There are no rules set for this connector`"
        hide-actions
    >
        <template
            slot="headerCell"
            slot-scope="{ header }"
        >
            <span
                class="subheading font-weight-light text-primary text--darken-3"
                v-text="header.text"
            />
        </template>

        <template
            slot="items"
            slot-scope="{ item }"
        >
            <td>{{ item.role }}</td>
            <td>{{ item.resource }}</td>
            <td>{{ item.action | formatValues }}</td>
            <td>{{ item.attributes | formatValues }}</td>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: 'ACLRules',

    filters: {
        formatValues(values) {
            if (Array.isArray(values)) {
                return values.join(', ');
            }

            return values;
        }
    },

    props: {
        rules: {
            type: Array,
            default: () => ([])
        }
    },

    data() {
        return {
            headers: [
                {
                    sortable: false,
                    text: 'Scope',
                    value: 'role'
                },
                {
                    sortable: false,
                    text: 'Resource',
                    value: 'resource'
                },
                {
                    sortable: false,
                    text: 'Action',
                    value: 'action'
                },
                {
                    sortable: false,
                    text: 'Attributes',
                    value: 'attributes'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
