import QueryUtils from '../query';
import appmixer from '../../plugins/appmixer';

export default {
    queryQuotas(query = {}) {
        query = QueryUtils.processQuery(query);
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/quota`,
            method: 'GET',
            params: query
        });
    },

    createOrUpdateQuota(name, source) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/quota/${name}`,
            method: 'PUT',
            data: {
                source
            }
        });
    },

    testQuota(source) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/quota/test`,
            method: 'POST',
            data: {
                source
            }
        });
    },

    deleteQuota(name) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/quota/${name}`,
            method: 'DELETE'
        });
    }
};
