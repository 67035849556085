<template>
    <v-layout column>
        <div
            v-if="vendors.length === 0 && !addVendorInput"
            class="pa-3"
        >
            No vendors set
        </div>
        <div v-else>
            <v-list>
                <v-list-tile
                    v-for="(vendor, idx) in vendors"
                    :key="idx"
                >
                    <template v-if="idx === editVendorInput">
                        <vendor-input
                            :initial-value="vendor"
                            @confirm="(value) => updateVendor(idx, value)"
                            @cancel="editVendorInput = null"
                        />
                    </template>
                    <template v-else>
                        <v-list-tile-content>{{ vendor }}</v-list-tile-content>

                        <v-list-tile-action>
                            <v-layout>
                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon
                                            class="mr-3"
                                            v-on="on"
                                            @click="editVendor(idx)"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span class="text-white">Edit</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon
                                            v-on="on"
                                            @click="removeVendor(idx)"
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                    <span class="text-white">Delete</span>
                                </v-tooltip>
                            </v-layout>
                        </v-list-tile-action>
                    </template>
                </v-list-tile>
            </v-list>
        </div>
        <v-layout v-if="addVendorInput">
            <vendor-input
                @confirm="addVendor"
                @cancel="addVendorInput = false"
            />
        </v-layout>
        <div v-else>
            <v-btn
                small
                color="primary"
                text
                @click="addVendorInput = true"
            >
                Add
            </v-btn>
        </div>
    </v-layout>
</template>

<script>
import VendorInput from './VendorInput';
export default {
    name: 'EditUserVendors',
    components: { VendorInput },
    props: {
        vendors: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            editVendorInput: null,
            addVendorInput: false
        };
    },
    methods: {
        addVendor(vendor) {
            this.addVendorInput = false;
            this.$emit('addVendor', vendor);
        },

        editVendor(index) {
            this.editVendorInput = index;
        },

        updateVendor(index, vendor) {
            this.editVendorInput = null;
            this.$emit('updateVendor', index, vendor);
        },

        removeVendor(index) {
            this.$emit('removeVendor', index);
        }
    }
};
</script>

<style scoped>

</style>
