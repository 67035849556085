export default `'use strict';

module.exports = {
  rules: [
    {
      // For complete information and examples on how to define the rules, 
      // visit https://docs.appmixer.com/appmixer/component-definition/quotas-and-limits#quota-module-structure
      limit: 100, // number of calls available during the specified window
      window: 1000 * 60 * 60 * 24, // time window specified in milliseconds
      
      // The throttling mechanism. Can be either a string 'window-sliding' or 
      // an object with type and getStartOfNextWindow function
      throttling: 'window-sliding', 
      
      queueing: 'fifo',   // Defines the order in which requests are processed
      resource: 'shares', // The identifier of the resource to which the rule applies 
      scope: 'userId'     // The scope for this rule
    }
  ]
};
`;
