import appmixer from '../../plugins/appmixer';

export default {

    queryComponentUsage(query) {
        const {
            componentType,
            stage,
            group,
            count,
            limit,
            offset
        } = query;
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/stats/component-usage`,
            method: 'GET',
            params: {
                componentType,
                stage,
                group,
                count,
                limit,
                offset
            }
        });
    },

    buildComponentUsageIndex() {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/stats/component-usage/build`,
            method: 'POST'
        });
    }
};
