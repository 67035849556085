import aclUtils from '../utils/acl';

export default {
    methods: {
        hasUserRule() {
            const acl = this.$store.state.permissions.acl;
            return aclUtils.hasUserRule(acl);
        },

        hasAdminPermission() {
            const acl = this.$store.state.permissions.acl;
            return aclUtils.hasAdminPermission(acl);
        },

        async removeUserAllAccessPermission() {
            const removeRules = [
                {
                    role: 'user',
                    action: ['*'],
                    resource: '*',
                    attributes: ['non-private']
                },
                {
                    role: 'user',
                    action: ['*'],
                    resource: '*',
                    attributes: ['private']
                }
            ];

            await this.$store.dispatch('permissions/editRules', { remove: removeRules });
        }
    }
};
