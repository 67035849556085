<template>
    <v-dialog
        ref="dialog"
        :value="show"
        width="700"
        @input="$emit('close')"
    >
        <v-card>
            <v-card-title class="font-weight-bold">
                Upload File
            </v-card-title>

            <v-card-text>
                <file-upload
                    ref="upload"
                    @file:change="updateFile"
                />
            </v-card-text>

            <v-card-actions>
                <div
                    v-if="loading"
                    class="am-edit-quota-dialog__loader-wrapper"
                >
                    <v-progress-circular
                        :size="30"
                        color="primary"
                        indeterminate
                    />
                </div>

                <template v-else>
                    <v-btn
                        :disabled="false"
                        color="primary"
                        text
                        @click="$emit('upload', file)"
                    >
                        Save
                    </v-btn>

                    <v-btn
                        color="grey"
                        text
                        @click="close"
                    >
                        Cancel
                    </v-btn>
                </template>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import FileUpload from '../../../components/common/File/Upload';
export default {
    name: 'UploadFileDialog',

    components: {
        FileUpload
    },

    props: {
        show: {
            type: Boolean,
            default: false
        },

        loading: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            file: null
        };
    },

    methods: {
        updateFile(file) {
            this.file = file;
        },

        clearDropzone() {
            this.$refs.upload.clearDropzone();
        },

        close() {
            this.$emit('close');
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
