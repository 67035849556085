import appmixer from '../../../plugins/appmixer';

export default {

    async authenticate({ commit, dispatch, state, getters }, { username, password, token }) {
        commit('setLoading', true);
        try {
            let accessToken = token;
            if (!accessToken) {
                const data = await appmixer.api.authenticateUser(username, password);
                appmixer.set('accessToken', data.token);
                accessToken = data.token;
            } else {
                appmixer.set('accessToken', accessToken);
            }

            await dispatch('queryUser');
            if (!state.user.scope.includes('admin')) {
                commit('unsetAccessToken');
                commit('setUser', {});
                commit('setError', 'You are not allowed to login into the backoffice');
                return;
            }
            commit('setAccessToken', accessToken);
            commit('setError', null);
        } catch (error) {
            if ((error.response || {}).status === 403) {
                commit('setError', 'Email and/or password does not match our records.\nPlease try again.');
            } else {
                commit('setError', 'Something went wrong signing-in.\nPlease try again later.');
            }
        }
        commit('setLoading', false);
    },

    async queryUser({ commit }) {
        const data = await appmixer.api.getUser();
        commit('setUser', data);
    }
};
