import _ from 'lodash';

export default {
    loaders(state) {
        return {
            apps: {
                available: state.apps.available.loading,
                installed: state.apps.installed.loading
            },
            components: {
                available: state.components.available.loading,
                installed: state.components.installed.loading
            }
        };
    },

    data(state) {
        return {
            apps: {
                available: state.apps.available.data,
                installed: state.apps.installed.data
            },
            components: {
                available: state.components.available.data,
                installed: state.components.installed.data
            }
        };
    },

    modules(state, getters) {
        const data = getters.data;
        const availableModules = (data.apps.available || []).map(c => {
            return { ...c, installed: false };
        });
        const installedModules = (data.apps.installed || []).map(c => {
            return { ...c, installed: true };
        });

        return _.uniqBy([...installedModules, ...availableModules], 'name');
    },

    components(state, getters) {
        const data = getters.data;
        const availableComponents = (data.components.available || [])
            .map(c => {
                return { ...c, installed: false, availableVersion: c.version };
            });
        const installedComponents = (data.components.installed || [])
            .map(c => {
                const installedVersion = c.version || '1.0.0';
                const availableVersion = (availableComponents.find(a => a.name === c.name) || {}).version || '1.0.0';
                return { ...c, installed: true, installedVersion, availableVersion };
            });

        return _.uniqBy([...installedComponents, ...availableComponents], 'name');
    }
};
