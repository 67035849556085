var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('v-layout',[_c('v-flex',{attrs:{"md4":""}},[_c('v-menu',{ref:"menuFrom",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFrom),callback:function ($$v) {_vm.menuFrom=$$v},expression:"menuFrom"}},[_c('v-date-picker',{attrs:{"type":"month","max":(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),"min":"2015-01-01"},on:{"change":_vm.changeDates},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('v-menu',{ref:"menuTo",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuTo),callback:function ($$v) {_vm.menuTo=$$v},expression:"menuTo"}},[_c('v-date-picker',{attrs:{"type":"month","max":(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),"min":"2015-01-01"},on:{"change":_vm.changeDates},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1)],1)],1),(_vm.result.totalCount)?_c('v-card',{staticClass:"mr-5",attrs:{"flat":""}},[_c('v-card-text',[_c('div',[_vm._v("Total Number of Messages:")]),_c('p',{staticClass:"display-1 text--primary text-center"},[_vm._v(" "+_vm._s(_vm.result.totalCount)+" ")])])],1):_vm._e(),(_vm.result.totalSize)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('div',[_vm._v("Total Messages Size:")]),_c('p',{staticClass:"display-1 text--primary text-center"},[_vm._v(" "+_vm._s(_vm._f("formatBytes")(_vm.result.totalSize,2))+" ")])])],1):_vm._e(),_c('v-flex',{attrs:{"md12":""}},[_c('material-card',{attrs:{"title":'Insights',"color":"primary"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.result.stats,"loading-data":_vm.loading,"hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"subheading font-weight-light text-primary text--darken-3",domProps:{"textContent":_vm._s(header.text)}})]}},{key:"items",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(item.date,'MMMM YYYY')))]),_c('td',[_vm._v(_vm._s(item.count)+" messages")]),_c('td',[_vm._v(_vm._s(_vm._f("formatBytes")(item.size,2)))])]}}])},[_c('template',{slot:"no-data"},[_c('div',{staticClass:"text-xs-center",staticStyle:{"width":"100%"}},[_vm._v(" No display ")])])],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }