<template>
    <v-container
        fluid
        grid-list-xl
    >
        <v-layout
            justify-center
            wrap
        >
            <upsert-quota-dialog
                ref="newQuota"
                :show="dialogs.newQuota.show"
                :value="dialogs.newQuota.quota"
                :loading="quotas.loading"
                :test-result="dialogs.testResult"
                @quota:upsert="createQuota"
                @quota:test="testQuota"
                @close="closeUpsertDialog('newQuota')"
            >
                Add quota definition
            </upsert-quota-dialog>

            <upsert-quota-dialog
                ref="editQuota"
                :show="dialogs.editQuota.show"
                :value="dialogs.editQuota.quota"
                :loading="quotas.loading"
                :test-result="dialogs.testResult"
                @quota:upsert="updateQuota"
                @quota:test="testQuota"
                @close="closeUpsertDialog('editQuota')"
            >
                Edit quota definition: {{ dialogs.editQuota.titleName }}
            </upsert-quota-dialog>

            <delete-quota-dialog
                :show="dialogs.deleteQuota.show"
                :loading="quotas.loading"
                :has-fallback-definition="dialogs.deleteQuota.hasFallbackDefinition"
                @confirm="deleteQuota(dialogs.deleteQuota.name)"
                @close="dialogs.deleteQuota.show = false"
            />

            <v-flex xs12>
                <v-btn
                    color="primary"
                    text
                    @click="showCreateModal"
                >
                    Add new quota definition
                </v-btn>
            </v-flex>

            <v-flex xs12>
                <v-data-table
                    :headers="headers"
                    :items="quotas.data"
                    :loading-data="quotas.loading"
                    hide-actions
                >
                    <template
                        slot="headerCell"
                        slot-scope="{ header }"
                    >
                        <span
                            class="subheading font-weight-light text-primary text--darken-3"
                            v-text="header.text"
                        />
                    </template>

                    <template
                        slot="items"
                        slot-scope="{ item }"
                    >
                        <td>{{ item.name }}</td>
                        <td>
                            <v-icon
                                class="action-icon"
                                @click="showEditModal(item)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                v-if="item.isCustom"
                                class="action-icon"
                                @click="showDeleteModal(item.name, item.defaultSource)"
                            >
                                mdi-delete
                            </v-icon>
                        </td>
                    </template>
                </v-data-table>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import defaultQuotaDefinition from './default-quota-definition';
import QuotaRequests from '../../utils/requests/quota';
import DeleteQuotaDialog from './delete-quota/Dialog';
import UpsertQuotaDialog from './upsert-quota/Dialog';

export default {
    name: 'QuotasView',

    components: {
        UpsertQuotaDialog,
        DeleteQuotaDialog
    },

    data() {
        return {
            dialogs: {
                testResult: {
                    type: null,
                    message: null,
                    loading: false
                },
                editQuota: {
                    show: false,
                    titleName: '',
                    quota: {
                        name: '',
                        source: ''
                    }
                },
                newQuota: {
                    show: false,
                    quota: {
                        name: '',
                        source: ''
                    }
                },
                deleteQuota: {
                    show: false,
                    name: '',
                    hasFallbackDefinition: false
                }
            },
            quotas: {
                loading: false,
                data: []
            },
            query: {
                manager: ''
            },
            headers: [
                {
                    sortable: false,
                    text: 'Service',
                    value: 'service'
                },
                {
                    sortable: false,
                    text: 'Definition',
                    value: 'definition'
                }
            ]
        };
    },

    created() {
        this.getQuotas();
    },

    methods: {
        async getQuotas(query) {
            this.quotas.loading = true;
            try {
                this.quotas.data = await QuotaRequests.queryQuotas(query);
            } finally {
                this.quotas.loading = false;
            }
        },

        async createQuota({ name, source }) {
            this.resetTestResult();
            try {
                await QuotaRequests.testQuota(source);
            } catch (err) {
                this.setTestResultError(err);
                return;
            }

            const quota = await QuotaRequests.createOrUpdateQuota(name, source);
            this.$set(this.dialogs, 'newQuota', {
                show: false,
                quota: {
                    name: '',
                    source: ''
                }
            });
            const currentData = [...this.quotas.data,
                { name: quota.name, source: quota.source, isCustom: quota.isCustom }];
            this.$set(this.quotas, 'data', currentData);
            this.resetTestResult();
            this.dialogs.testResult.loading = false;
        },

        async updateQuota({ name, source }) {
            this.resetTestResult();
            try {
                await QuotaRequests.testQuota(source);
            } catch (err) {
                this.setTestResultError(err);
                return;
            }

            const currentName = this.dialogs.editQuota.titleName;
            if (currentName !== name) {
                await QuotaRequests.deleteQuota(currentName);
            }
            await QuotaRequests.createOrUpdateQuota(name, source);
            this.quotas.data = await QuotaRequests.queryQuotas(this.query);
            this.dialogs.editQuota.show = false;
            this.quotas.loading = false;
            this.resetTestResult();
            this.dialogs.testResult.loading = false;
        },

        async testQuota(source) {
            try {
                this.resetTestResult();
                this.dialogs.testResult.loading = true;
                await QuotaRequests.testQuota(source);
                this.dialogs.testResult.type = 'success';
                this.dialogs.testResult.message = 'Your quota definition is valid!';
            } catch (err) {
                this.setTestResultError(err);
            }
            this.dialogs.testResult.loading = false;
        },

        setTestResultError(err) {
            this.dialogs.testResult.type = 'error';
            this.dialogs.testResult.message = err.response.data.message;
        },

        async deleteQuota(name) {
            this.quotas.loading = true;
            await QuotaRequests.deleteQuota(name);
            this.quotas.data = await QuotaRequests.queryQuotas(this.query);
            this.quotas.loading = false;
            this.dialogs.deleteQuota.show = false;
        },

        showCreateModal() {
            // The default quota definition which serves as example when creating a new quota
            const defaultQuota = defaultQuotaDefinition;

            this.$set(this.dialogs, 'newQuota', {
                show: true,
                quota: {
                    name: '',
                    source: defaultQuota
                }
            });
        },

        showEditModal({ name, source }) {
            this.$set(this.dialogs, 'editQuota', {
                show: true,
                titleName: name,
                quota: { name, source }
            });
        },

        showDeleteModal(name, defaultSource) {
            this.$set(this.dialogs, 'deleteQuota', {
                show: true,
                name,
                hasFallbackDefinition: !!defaultSource
            });
        },

        closeUpsertDialog(dialog) {
            this.dialogs[dialog].show = false;
            this.resetTestResult();
            this.dialogs.testResult.loading = false;
        },

        resetTestResult() {
            this.dialogs.testResult.type = null;
            this.dialogs.testResult.message = null;
        }
    }
};
</script>

<style scoped>

</style>
