import appmixer from '../../plugins/appmixer';

export default {

    getTypes() {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/acl-types`,
            method: 'GET'
        });
    },

    getRules(type) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/acl/${type}`,
            method: 'GET'
        });
    },

    updateRule(type, payload) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/acl/${type}`,
            method: 'POST',
            data: payload
        });
    },

    getResources(type) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/acl/${type}/resources`,
            method: 'GET'
        });
    },

    getActions(type) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/acl/${type}/actions`,
            method: 'GET'
        });
    },

    getAttributes(type, resource) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/acl/${type}/resource/${resource}/attributes`,
            method: 'GET'
        });
    }
};
