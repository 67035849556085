<template>
    <v-dialog
        :value="show"
        class="am-edit-user-dialog"
        width="500"
        @input="($event) => $emit('updateShow', $event)"
    >
        <v-card>
            <v-card-title class="am-edit-user-dialog__title">
                Edit User: {{ currentUser.email }}
            </v-card-title>

            <v-card-text>
                Scopes:
                <v-layout justify="space-around">
                    <v-checkbox
                        v-for="(scope, idx) in $options.scopes"
                        :key="idx"
                        v-model="currentUser.scope"
                        :label="scope.label"
                        :value="scope.value"
                    />
                </v-layout>
                <v-divider />
                Vendor:
                <vendors
                    :vendors="currentUser.vendor"
                    @addVendor="addVendor"
                    @updateVendor="updateVendor"
                    @removeVendor="removeVendor"
                />
            </v-card-text>

            <v-divider />

            <v-card-actions class="am-edit-user-dialog__buttons">
                <v-btn
                    color="primary"
                    text
                    @click.stop="emitSave"
                >
                    Save
                </v-btn>
                <v-btn
                    color="grey"
                    text
                    @click.stop="$emit('cancel')"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Vendors from './Vendors';

export default {
    name: 'EditUserDialog',
    components: { Vendors },
    scopes: [
        { label: 'User', value: 'user' },
        { label: 'Admin', value: 'admin' },
        { label: 'Private', value: 'private' }
    ],
    model: {
        prop: 'show',
        event: 'updateShow'
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            showDialog: false,
            currentUser: {}
        };
    },
    watch: {
        show(val) {
            this.showDialog = val;
        },
        user(val) {
            this.currentUser = { ...(val || {}) };
        }
    },
    methods: {
        addVendor(vendor) {
            this.currentUser.vendor.push(vendor);
        },

        updateVendor(index, vendor) {
            this.$set(this.currentUser.vendor, index, vendor);
        },

        removeVendor(index) {
            this.$delete(this.currentUser.vendor, index);
        },

        emitSave() {
            const nonEmptyVendors = this.currentUser.vendor.filter(input => input !== '');
            const payload = {
                id: this.currentUser.id,
                payload: {
                    scope: this.currentUser.scope,
                    vendor: nonEmptyVendors
                }
            };
            this.$emit('save', payload);
        }
    }
};
</script>

<style lang="scss" scoped>

  .am-edit-user-dialog {
    &__title {
      font-weight: bold;
    }
  }

</style>
