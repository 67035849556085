import appmixer from '../../plugins/appmixer';

export default {

    getInsights(from, to) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/telemetry/messages?from=${from}&to=${to}`,
            method: 'GET'
        });
    }
};
