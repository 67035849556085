import ACL from '../services/ACL';

export default {
    hasUserRule(acl) {
        return acl.some(rule => {
            return rule.role === 'user' &&
                rule.resource === '*' &&
                rule.action.includes('*');
        });
    },

    hasAdminPermission(acl) {
        return acl.some(rule => {
            return rule.role === 'admin' &&
                rule.resource === '*' &&
                rule.action.includes('*');
        });
    },

    ruleCanUseModule(module, rule) {
        const acl = new ACL([rule]);
        return acl.can(rule.role, 'use', module);
    }
};
