<template>
    <v-container
        fluid
        grid-list-xl
    >
        <v-layout justify-end>
            <v-btn
                color="primary"
                text
                @click="add"
            >
                Add Item
            </v-btn>
            <v-btn
                color="grey"
                text
                @click="cancel"
            >
                Cancel
            </v-btn>
        </v-layout>

        <v-layout
            justify-center
            wrap
        >
            <confirm-dialog
                :show="confirmDeleteDialog.show"
                @confirm="removeServiceKey"
                @cancel="hideConfirmDeleteDialog"
            >
                <template #header>
                    Confirm action
                </template>
                <template #body>
                    Are you sure you want to delete this entry?
                </template>
            </confirm-dialog>

            <v-flex xs12>
                <v-alert
                    :value="validationResult"
                    type="error"
                    class="modules__alert"
                    dismissible
                >
                    <span>{{ validationResult }}</span>
                </v-alert>
                <material-card
                    :title="serviceId"
                    color="primary"
                >
                    <service-config-table
                        :loading="loading"
                        :service="service"
                        :creating="creating"
                        :types="types"
                        @create="createServiceKey"
                        @cancel-create="cancelCreate"
                        @update="updateService"
                        @delete="showConfirmDeleteDialog"
                    />
                </material-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import _ from 'lodash';
import AuthConfigRequests from '../../utils/requests/authConfig';
import ServiceConfigTable from './ServiceConfigTable';
import NewServiceDialog from './NewServiceDialog';
import ConfirmDialog from '../../components/common/ConfirmDialog';

export default {
    name: 'ServiceConfig',
    metaInfo() {
        return {
            title: 'Appmixer Backoffice - Connectors Configuration'
        };
    },
    components: {
        NewServiceDialog,
        ServiceConfigTable,
        ConfirmDialog
    },
    props: {
        serviceId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            loading: false,
            moduleId: '',
            validationResult: '',
            defaults: {},
            types: [],
            missedAttrs: [],
            service: {},
            creating: false,
            confirmDeleteDialog: {
                show: false,
                key: null
            }
        };
    },

    created() {
        this.loadServiceConfig();
        this.moduleId = this.serviceId.replace(/\./g, ':');
        this.validateConfig(this.moduleId);
    },

    methods: {
        add() {
            this.creating = true;
        },

        cancel() {
            this.$router.push({ path: '/dashboard/service-config' });
        },

        cancelCreate() {
            this.creating = false;
        },

        async loadServiceConfig() {
            this.loading = true;
            try {
                this.service = await AuthConfigRequests.getAuthConfig(this.serviceId);
            } finally {
                this.loading = false;
            }
        },

        async createServiceKey({ key, value }) {
            this.creating = false;
            this.service = await AuthConfigRequests.updateAuthConfig(this.serviceId, {
                ...this.service,
                [key]: value
            });
            await this.validateConfig(this.moduleId);
        },

        async updateService({ oldKey, key, value }) {
            const payload = _.omit(this.service, [oldKey, 'serviceId']);
            payload[key] = value;
            this.service = await AuthConfigRequests.updateAuthConfig(this.serviceId, payload);
            await this.validateConfig(this.moduleId);
        },

        async validateConfig(moduleId) {
            const status = await AuthConfigRequests.validateConfig(moduleId);
            const propKeys = Object.keys(status.configurationSchema.properties);
            let defaults = {};
            propKeys.forEach((key) => {
                if (status.configurationSchema.properties[key].default) {
                    defaults[key] = status.configurationSchema.properties[key].default;
                }
            });

            // if module was just installed and not configured
            if (Object.keys(defaults).length && Object.keys(this.service).length === 0) {
                // Create AuthConfig if defaults(schema) has default values
                this.service = await AuthConfigRequests.updateAuthConfig(this.serviceId, defaults);
            }
            // this.defaults = defaults;
            propKeys.forEach((key) => {
                this.types[key] = status.configurationSchema.properties[key].type;
            });
            const configValidationErrors = status.errors || [];
            if (configValidationErrors.length >= 1) {
                const missingValues = status.errors.map((error) => error.params.missingProperty)
                    .filter((item) => item).join(', ');
                if (missingValues.length) {
                    this.validationResult = `Config should have required properties: ${missingValues} `;
                }
            }
        },

        showConfirmDeleteDialog(key) {
            this.confirmDeleteDialog.show = true;
            this.confirmDeleteDialog.key = key;
        },

        hideConfirmDeleteDialog() {
            this.confirmDeleteDialog.show = false;
            this.confirmDeleteDialog.key = null;
        },

        async removeServiceKey() {
            const payload = _.omit(this.service, [this.confirmDeleteDialog.key, 'serviceId']);
            this.hideConfirmDeleteDialog();
            this.service = await AuthConfigRequests.updateAuthConfig(this.serviceId, payload);
            await this.validateConfig(this.moduleId);
        }
    }
};
</script>

<style scoped>

</style>
