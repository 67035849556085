export default {
    methods: {
        async loadModuleData() {
            await this.$store.dispatch('modules/loadModuleData');
        },

        async loadComponentData(module, version) {
            const components = this.$store.getters['modules/components'];
            const moduleComponents = components.filter(c => c.name.startsWith(module));
            if (moduleComponents.length === 0) {
                const availableModules = this.$store.getters['modules/data'].apps.available;

                if (availableModules.find(m => m.name === module)) {
                    // Load components from marketplace and system
                    await this.$store.dispatch('modules/loadComponentData', { module, version });
                } else {
                    // Module not in marketplace, load only from system
                    await this.$store.dispatch('modules/loadInstalledComponents');
                }
            }
        }
    }
};
