import AccessControl from 'role-acl';

export default class ACL {
    constructor(rules = []) {
        this.acl = new AccessControl();
        this.addRules(rules);
    }

    addRules(rules) {
        rules.forEach(rule => {
            this.acl.grant(rule.role).execute(rule.action).on(rule.resource);
        });
    }

    can(role, action, resource) {
        try {
            return this.acl.can(role).execute(action).sync().on(resource);
        } catch (err) {
            if (err.message.includes('Scope not found')) {
                return false;
            } else if (err.message.includes('Role not found')) {
                return false;
            }
            throw err;
        }
    }
}
