<template>
    <div>
        <v-card-text>
            Scope:
            <v-text-field
                v-model="role"
                :error="submitted && !$v.role.required"
                :hide-details="!submitted || $v.role.required"
                error-messages="Scope is required"
                class="mb-3"
                type="text"
            />

            Resource:
            <v-text-field
                v-model="resource"
                :error="submitted && (!$v.resource.required || !$v.resource.pattern)"
                :hide-details="!submitted || ($v.resource.required && $v.resource.pattern)"
                type="text"
                error-messages="Resource is invalid"
                class="mb-3"
                @blur="($event) => $emit('getAttributes', $event.target.value)"
            />

            Action:
            <v-layout class="mb-3">
                <multiselect
                    v-model="action"
                    :options="actions"
                    :multiple="true"
                    :searchable="false"
                    :show-labels="false"
                    :max-height="200"
                    :open-direction="'above'"
                    placeholder="Select action"
                />
            </v-layout>

            <div
                v-if="submitted && !$v.action.required"
                class="invalid-feedback mb-3"
            >
                Action is required
            </div>

            Attributes:
            <v-layout>
                <multiselect
                    v-model="attributes"
                    :options="attributeOptions"
                    :multiple="attributesType === 'multiselect'"
                    :taggable="attributesType === 'multiselect'"
                    :searchable="attributesType === 'multiselect'"
                    :allow-empty="attributesType === 'multiselect'"
                    :show-labels="false"
                    tag-placeholder="Add this as new attribute"
                    placeholder="Select attributes"
                />
            </v-layout>

            <div
                v-if="attributeTooltip"
                class="tooltip"
            >
                {{ attributeTooltip }}
            </div>

            <div
                v-if="submitted && !$v.attributes.required"
                class="invalid-feedback"
            >
                Attributes is required
            </div>
        </v-card-text>

        <v-card-actions class="am-update-acl-dialog__buttons">
            <v-btn
                color="primary"
                text
                @click.stop="emitSave"
            >
                Save
            </v-btn>
            <v-btn
                color="grey"
                text
                @click.stop="$emit('cancel')"
            >
                Cancel
            </v-btn>
        </v-card-actions>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { required } from 'vuelidate/lib/validators';
import { pattern } from '@/utils/validators';

export default {
    name: 'ComponentsForm',

    validations() {
        return {
            role: { required },
            resource: { required, pattern: pattern(this.resourcePattern) },
            action: { required },
            attributes: { required }
        };
    },

    components: {
        Multiselect
    },

    props: {
        initialValues: {
            type: Object,
            default: null
        },
        resourcePattern: {
            type: String,
            required: true
        },
        actions: {
            type: Array,
            required: true
        },

        aclAttributes: {
            type: Array,
            required: true
        },

        attributesType: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            role: '',
            resource: '',
            action: [],
            attributes: '',
            submitted: false
        };
    },

    computed: {
        attributeOptions() {
            return this.aclAttributes.map(a => a.value);
        },

        attributeTooltip() {
            const attribute = this.aclAttributes.find(attr => attr.value === this.attributes);
            return (attribute || {}).tooltip;
        }
    },

    created() {
        if (this.initialValues) {
            this.initForm(this.initialValues);
        }
    },

    methods: {
        initForm(val) {
            this.role = val.role;
            this.resource = val.resource;
            this.action = val.action;
            this.attributes = val.attributes[0];
        },

        emitSave() {
            this.submitted = true;

            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const payload = {
                role: this.role,
                resource: this.resource,
                action: this.action,
                attributes: [this.attributes]
            };

            this.$emit('save', payload);
        }
    }
};
</script>

<style scoped>
.invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.tooltip {
    width: 100%;
    margin-top: .25rem;
    font-size: 90%;
    color: #4caf50;
}
</style>
