<template>
    <div>
        <v-alert
            :value="adminAlert.properties.show"
            :type="adminAlert.properties.type"
            class="modules__alert"
            dismissible
        >
            There is a missing ACL rule for the admin that is required for
            this functionality. Click <a @click="createAdminPermissions">here</a> to fix it.
        </v-alert>

        <user-acl-alert
            :alert="userAlert"
            class="modules__alert"
            @fix="goToACL"
        />

        <v-alert
            :value="hasUpdatesAvailable"
            type="info"
            class="modules__alert modules__alert-info"
            dismissible
        >
            Some connectors have updates available.
            Click <a
                class="modules__alert modules__alert-info-link"
                @click="() => filters.update = 'updateAvailable'"
            >here</a> to see them.
        </v-alert>

        <v-alert
            :value="alert.properties.value"
            :type="alert.properties.type"
            class="modules__alert"
            dismissible
        >
            {{ alert.properties.message }}
        </v-alert>

        <v-alert
            :value="invalidServices.length"
            type="error"
            class="modules__alert"
            dismissible
        >
            <span>Uploaded services: <span
                v-for="(item, idx) in invalidServices"
                :key="idx"
                class="modules__config-link"
            >
                {{ item }}</span>
                use authentication, but they may not be configured yet.</span>
        </v-alert>

        <v-alert
            :value="invalidModules.length"
            type="error"
            class="modules__alert"
            dismissible
        >
            <span>Uploaded modules: <span
                v-for="(item, idx) in invalidModules"
                :key="idx"
            ><a
                class="modules__config-link"
                target="_blank"
                @click="openModuleDetailRoute(item)"
            >{{ item }}</a> </span>
                use authentication, but they may not be configured yet.</span>
        </v-alert>

        <v-container
            fluid
            grid-list-xl
        >
            <v-layout
                justify-center
                wrap
            >
                <module-upload-modal
                    ref="moduleUpload"
                    :show="uploadModal.show"
                    :loading="uploadingModule"
                    @close="uploadModal.show = false"
                    @upload="uploadModule"
                />

                <v-flex xs12>
                    <module-filters
                        v-model="filters"
                    />
                </v-flex>
            </v-layout>

            <v-layout
                v-if="loadingModules"
                class="mt-5"
                justify-center
            >
                <v-progress-circular
                    :size="40"
                    color="primary"
                    indeterminate
                />
            </v-layout>

            <v-layout
                v-else
                justify-center
                wrap
            >
                <v-flex
                    xs12
                    class="mt-5"
                >
                    <modules-list
                        :modules="filteredModules"
                        @selected="moduleSelected"
                    />
                </v-flex>

                <v-flex
                    class="mt-5"
                    xs12
                >
                    <v-layout justify-center>
                        <v-btn
                            v-if="!uploadingModule"
                            color="primary"
                            text
                            @click="openUploadModule"
                        >
                            Add a connector from your filesystem
                        </v-btn>
                        <div v-else>
                            Uploading your connector. This can take a few minutes...
                            <v-progress-circular
                                :size="30"
                                color="primary"
                                indeterminate
                            />
                        </div>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import _ from 'lodash';
import ModulesList from './ModulesList';
import ModuleUploadModal from './upload-module/Modal';
import UserACLAlert from './UserACLAlert';
import ModuleFilters from './Filters';
import uploadUtils from '../../utils/upload';
import ModulesMixin from '../../mixins/views/modules/modules';
import AlertMixin from '../../mixins/alert';
import ACLUtilsMixin from '../../mixins/aclUtils';
import ValidateModule from './utils/ValidateModule';
import UploadStatus from './utils/UploadStatus';
import { compare } from 'compare-versions';

export default {
    name: 'Modules',

    components: {
        ModuleFilters,
        ModuleUploadModal,
        ModulesList,
        UserAclAlert: UserACLAlert
    },

    mixins: [
        ModulesMixin,
        AlertMixin,
        ACLUtilsMixin
    ],

    data() {
        return {
            uploadModal: {
                show: false
            },
            filters: {
                name: '',
                status: 'all',
                update: 'all',
                vendor: 'all'
            },
            uploadingModule: false,
            invalidModules: [],
            invalidServices: [],
            alert: this.createAlert(),
            adminAlert: this.createAlert(),
            userAlert: this.createAlert()
        };
    },

    computed: {
        loadingModules() {
            const loaders = this.$store.getters['modules/loaders'];
            return loaders.apps.installed || loaders.apps.available;
        },
        modules() {
            const data = this.$store.getters['modules/data'];
            const { installed, available } = data.apps;

            const uninstalledModules = _.differenceBy(available, installed, 'name')
                .map(m => ({ ...m, installed: false }));

            const installedModules = installed.map(m => {
                const aModule = available.find(i => i.name === m.name) || {};

                const availableVersion = aModule.bundleVersion || '1.0.0';
                const installedVersion = m.bundleVersion || '1.0.0';

                const upgradeAvailable = compare(availableVersion, installedVersion, '>');

                return { ...m, installed: true, upgradeAvailable: upgradeAvailable };
            });

            return _.sortBy([...uninstalledModules, ...installedModules], 'label');
        },

        filteredModules() {
            const { name, status, update, vendor } = this.filters;
            return this.modules.filter(m => {
                const conditions = [];
                if (name.length > 0) {
                    const label = m.label || m.name;
                    conditions.push(new RegExp(name, 'i').test(label));
                }

                if (status === 'installed') {
                    conditions.push(m.installed);
                }

                if (status === 'notInstalled') {
                    conditions.push(!m.installed);
                }

                if (update === 'updateAvailable') {
                    conditions.push(m.upgradeAvailable);
                }

                if (update === 'updateNotAvailable') {
                    conditions.push(!m.upgradeAvailable);
                }

                if (vendor === 'appmixer') {
                    conditions.push(m.name.startsWith('appmixer.'));
                }

                if (vendor === 'others') {
                    conditions.push(!m.name.startsWith('appmixer.'));
                }
                return conditions.every(c => c);
            });
        },

        hasUpdatesAvailable() {
            return this.modules.some(m => m.upgradeAvailable);
        }
    },

    async created() {
        this.loadModuleData();
        await this.loadACL();
        this.checkUserPermission(this.userAlert.controller);

        const { status } = await UploadStatus.status('filesystem-module');
        if (status === UploadStatus.constants.UPLOADING) {
            this.setUploadLoader(true);
            try {
                await UploadStatus.waitForUpload('filesystem-module');
            } catch (err) {
                this.alert.controller.showAlert(
                    'error', `An error occurred during connector installation: ${err.message || err}`);
            }
            try {
                await Promise.all([
                    this.$store.dispatch('modules/loadInstalledModules', { withLoaders: false }),
                    this.$store.dispatch('modules/loadInstalledComponents', { withLoaders: false })
                ]);
                this.alert.controller.showAlert('success', 'The connector has been uploaded successfully');
            } catch (err) {
                this.alert.controller.showAlert(
                    'error', `An error occurred while loading the connector: ${err.message || err}`);
            }
            this.setUploadLoader(false);
        }
    },

    methods: {
        moduleSelected(name) {
            this.$router.push({ path: `/dashboard/modules/${name}` });
        },

        openModuleDetailRoute(module) {
            const moduleRoute = module.split(':').join('.');
            this.$router.push({ path: `/dashboard/modules/${moduleRoute}` });
        },

        setUploadLoader(loading) {
            this.uploadingModule = loading;
        },

        async validateInstalledModules(installed) {
            const modulesToValidate = installed.modules || installed.services;
            const invalidModules = [];
            const invalidServices = [];
            for (let i = 0; i < modulesToValidate.length; i++) {
                const moduleId = modulesToValidate[i].replace(/\./g, ':');
                const validationResponse = await ValidateModule.validateConfig(moduleId, true);
                if (validationResponse.isModuleConfigValid === false) {
                    invalidModules.push(moduleId);
                }
                if (validationResponse.isServiceConfigValid === false) {
                    const serviceId = moduleId.split(':').slice(0, -1).join(':');
                    if (!invalidServices.includes(serviceId)) {
                        invalidServices.push(serviceId);
                    }
                }
            }
            this.invalidModules = invalidModules;
            this.invalidServices = invalidServices;
        },

        openUploadModule() {
            this.$refs.moduleUpload.reset();
            this.uploadModal.show = true;
        },

        async loadACL() {
            await this.$store.dispatch('permissions/initializeACL');
            this.checkAdminPermission();
        },

        checkAdminPermission() {
            if (!this.hasAdminPermission()) {
                this.adminAlert.controller.showAlert('warning', 'Admin not set');
            } else {
                this.adminAlert.controller.closeAlert();
            }
        },

        async uploadFile(file) {
            const { ticket } = await uploadUtils.uploadModule(file);
            UploadStatus.saveTicket('filesystem-module', ticket);
            await UploadStatus.waitForUploadByTicket(ticket);
            const { installed } = await UploadStatus.status('filesystem-module');
            await this.validateInstalledModules(installed);
        },

        async uploadModule(file) {
            this.setUploadLoader(true);
            this.alert.controller.closeAlert();
            try {
                await this.uploadFile(file);
                await Promise.all([
                    this.$store.dispatch('modules/loadInstalledModules', { withLoaders: false }),
                    this.$store.dispatch('modules/loadInstalledComponents', { withLoaders: false })
                ]);
                this.uploadModal.show = false;
                this.alert.controller.showAlert('success', 'The connector has been uploaded successfully');
            } catch (err) {
                this.alert.controller.showAlert(
                    'error', `An error occurred while uploading the module: ${err.message || err}`);
            } finally {
                this.setUploadLoader(false);
            }
        },

        changedFilters(filters) {
            this.filters = filters;
        },

        async createAdminPermissions() {
            const rule = {
                role: 'admin',
                action: ['*'],
                resource: '*',
                attributes: ['non-private']
            };
            await this.$store.dispatch('permissions/addRule', { rule });
            this.checkAdminPermission();
            await this.loadModuleData();
        },

        goToACL() {
            this.$router.push({ path: '/dashboard/acl/components' });
        },

        checkUserPermission(alertController) {
            if (this.hasUserRule()) {
                alertController.showAlert('warning');
            } else {
                alertController.closeAlert();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.modules {
  &__alert {
    position: sticky;
    top: 0;
    z-index: 1000;
  }

  &__alert-info {
    background-color: #1565c0 !important;
  }

  &__config-link {
    color: white;
    text-decoration: underline;
  }
}
</style>
