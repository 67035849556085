export default {
    apps: {
        available: {
            loading: false,
            data: []
        },
        installed: {
            loading: false,
            data: []
        }
    },
    components: {
        available: {
            loading: false,
            data: []
        },
        installed: {
            loading: false,
            data: []
        }
    }
};
