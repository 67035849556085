<template>
    <v-layout
        wrap
    >
        <v-flex
            v-for="(component, index) in components"
            :key="index"
            sm4
        >
            <div
                class="components-list__component"
            >
                <div class="components-list__component-label">
                    <img
                        :src="component.icon"
                        class="components-list__component-label-icon"
                    >

                    <span>
                        {{ componentName(component) }}

                        <v-tooltip top>
                            <template #activator="{ on }">
                                <div
                                    v-if="hasNewVersion(component)"
                                    class="components-list__update-available"
                                    v-on="on"
                                >
                                    !
                                </div>
                            </template>
                            <span class="text-white">New version {{ component.availableVersion }} is available</span>
                        </v-tooltip>
                    </span>
                </div>

                <div class="components-list__component-version">
                    {{ component.installedVersion || component.availableVersion }} version
                </div>

                <div
                    class="components-list__component-description"
                    v-text="component.description"
                />
            </div>
        </v-flex>
    </v-layout>
</template>

<script>
import { compare } from 'compare-versions';

export default {
    name: 'ComponentsList',

    props: {
        components: {
            type: Array,
            default: () => []
        }
    },

    computed: {

    },

    methods: {
        componentName(component) {
            return component.label || component.name.split('.').reverse()[0];
        },

        hasNewVersion(component) {
            return component.installed && compare(component.installedVersion, component.availableVersion, '<');
        }
    }
};
</script>

<style lang="scss" scoped>
  .components-list {
    &__component {
      position: relative;
      box-sizing: border-box;
      padding: 24px;
      max-width: 500px;
      border: solid 1px #e8e8e8;
      border-radius: 4px;
      background: white;
      text-decoration: none;

      &:hover {
        border-color: #2786FF;
      }
    }

    &__component-actions {
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &__component-label {
      display: flex;
      align-items: center;
      font: 16px 'nunitosans-bold';
      color: #222;
    }

    &__component-label-icon {
      display: inline-block;
      width: 22px;
      height: 22px;
      color: #222;
      margin-right: 8px;
    }

    &__update-available {
      display: inline-block;
      width: 22px;
      border-radius: 22px;
      background-color: rgb(156, 39, 176);
      color: white;
      text-align: center;
    }

    &__component-version {
      margin-top: 5px;
      font-size: 14px;
      font-weight: bold;
    }

    &__component-description {
      max-width: 335px;
      margin-top: 6px;
      font: 14px 'nunitosans-regular';
      line-height: 1.3em;
      color: #333;
    }
  }
</style>
