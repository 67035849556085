<template>
    <div>
        <v-alert
            :value="userRuleAlert.properties.value && type === 'components'"
            :type="userRuleAlert.properties.type"
            class="fixed-alert"
            dismissible
        >
            There is a rule (user * *) that allows all users to use all connectors. This can be intended, but
            it is recommended to have one ACL rule per each connector. You can click the button ADD USER RULES FOR
            INSTALLED CONNECTORS
            to automatically create a rule for each installed connector and then you can click
            <a @click="() => removeUserPermissions(userRuleAlert.controller)">here</a> to remove the generic one.
        </v-alert>

        <v-container
            fluid
            grid-list-xl
        >
            <v-layout>
                <v-flex
                    xs12
                    sm6
                >
                    <v-btn
                        v-if="type === 'components'"
                        color="primary"
                        text
                        @click="createUserRules()"
                    >
                        Add user rules for installed connectors
                    </v-btn>
                </v-flex>

                <v-flex
                    xs12
                    sm6
                >
                    <v-layout class="mt-0 mb-0 justify-right">
                        <v-btn
                            color="primary"
                            text
                            @click="editRule()"
                        >
                            Add
                        </v-btn>
                        <v-btn
                            color="grey"
                            text
                            @click="cancel"
                        >
                            Cancel
                        </v-btn>
                    </v-layout>
                </v-flex>
            </v-layout>

            <v-layout
                wrap
            >
                <confirm-dialog
                    v-bind="confirmDialog.properties"
                >
                    <template slot="action-buttons">
                        <v-btn
                            color="primary"
                            text
                            @click.stop="confirmDialog.controller.closeDialog"
                        >
                            Confirm
                        </v-btn>
                    </template>
                </confirm-dialog>

                <update-acl-dialog
                    v-model="editDialog.show"
                    :type="aclType"
                    :rule="editDialog.currentItem"
                    :acl-resources="editDialog.resources"
                    :actions="aclActions"
                    :acl-attributes="editDialog.attributes"
                    @getAttributes="resource => getAttributes(aclType, resource)"
                    @save="updateRule"
                    @cancel="closeDialog"
                />

                <v-flex
                    md12
                >
                    <material-card
                        :title="'ACL (' + aclType + ')'"
                        color="primary"
                    >
                        <v-data-table
                            :headers="headers"
                            :items="aclRules"
                            :loading-data="loading"
                            :no-data-text="`Rules not found. No one will be able to access ${aclType} on Appmixer`"
                            hide-actions
                        >
                            <template
                                slot="headerCell"
                                slot-scope="{ header }"
                            >
                                <span
                                    class="subheading font-weight-light text-primary text--darken-3"
                                    v-text="header.text"
                                />
                            </template>

                            <template
                                slot="items"
                                slot-scope="{ item }"
                            >
                                <td>{{ item.role }}</td>
                                <td>{{ item.resource }}</td>
                                <td>{{ item.action | formatValues }}</td>
                                <td>{{ item.attributes | formatValues }}</td>
                                <td>
                                    <v-icon
                                        class="mr-3 edit-icon"
                                        @click="editRule(item)"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon
                                        class="delete-icon"
                                        @click="deleteRule(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </td>
                            </template>
                        </v-data-table>
                    </material-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import AclRequests from '../../utils/requests/acl';
import UpdateAclDialog from './update-dialog/UpdateAclDialog';
import ACLUtilsMixin from '../../mixins/aclUtils';
import AlertMixin from '../../mixins/alert';
import ConfirmDialogMixin from '../../mixins/confirmDialog';
import ConfirmDialog from '../../components/common/ConfirmDialog/Basic';

export default {
    name: 'AclRules',

    metaInfo() {
        return {
            title: 'Appmixer Backoffice - ACL'
        };
    },

    filters: {
        formatValues(values) {
            if (Array.isArray(values)) {
                return values.join(', ');
            }

            return values;
        }
    },

    components: {
        UpdateAclDialog,
        ConfirmDialog
    },

    mixins: [
        AlertMixin,
        ConfirmDialogMixin,
        ACLUtilsMixin
    ],

    data() {
        return {
            confirmDialog: this.createConfirmDialog(),
            userRuleAlert: this.createAlert(),
            headers: [
                {
                    sortable: false,
                    text: 'Scope',
                    value: 'role'
                },
                {
                    sortable: false,
                    text: 'Resource',
                    value: 'resource'
                },
                {
                    sortable: false,
                    text: 'Action',
                    value: 'action'
                },
                {
                    sortable: false,
                    text: 'Attributes',
                    value: 'attributes'
                }, {
                    sortable: false,
                    text: '',
                    value: 'actions'
                }
            ],
            loading: false,
            aclType: '',
            aclRules: [],
            aclResources: [],
            aclActions: [],
            aclAttributes: {},
            editDialog: {
                show: false,
                currentItem: {},
                previousItem: null,
                resources: null,
                attributes: null
            },
            type: this.$route.params.type,
            hasUserAllRule: false
        };
    },

    async created() {
        await this.getRules(this.type);
        this.checkUserPermission(this.userRuleAlert.controller);
    },

    methods: {

        async getRules(type) {
            this.aclType = type;

            this.loading = true;
            try {
                this.aclRules = await AclRequests.getRules(type);
                // We use the modules store actions in createUserRules, so we need to sync the
                // store state
                if (type === 'components') {
                    this.$store.commit('permissions/setACL', JSON.parse(JSON.stringify(this.aclRules)));
                }
            } finally {
                this.loading = false;
                await this.getResources(type);
                await this.getActions(type);
                await this.getAttributes(type, '*');
            }
        },

        async getResources(type) {
            try {
                this.aclResources = await AclRequests.getResources(type);
            } catch (err) {
                this.aclResources = [];
            }
        },

        async getActions(type) {
            try {
                this.aclActions = await AclRequests.getActions(type);
            } catch (err) {
                this.aclActions = [];
            }
        },

        async getAttributes(type, resource) {
            if (!resource) {
                return;
            }

            try {
                this.aclAttributes = await AclRequests.getAttributes(type, resource);
            } catch (err) {
                this.aclAttributes = {};
            }
        },

        editRule(item = null) {
            this.editDialog.previousItem = item;
            this.editDialog.currentItem = item;
            this.editDialog.resources = this.aclResources;
            this.editDialog.attributes = this.aclAttributes;
            this.editDialog.show = true;
        },

        closeDialog() {
            this.editDialog.show = false;
            this.editDialog.previousItem = null;
            this.editDialog.currentItem = {};
            this.editDialog.resources = null;
            this.editDialog.attributes = {
                type: '',
                options: []
            };
        },

        async updateRule(value) {
            const rules = [...this.aclRules];

            if (this.editDialog.previousItem) {
                const index = rules.indexOf(this.editDialog.previousItem);

                if (index > -1) {
                    rules.splice(index, 1, value);
                } else {
                    rules.push(value);
                }
            } else {
                rules.push(value);
            }

            await AclRequests.updateRule(this.aclType, rules);

            this.aclRules = [...rules];
            this.closeDialog();
        },

        async deleteRule(value) {
            const index = this.aclRules.indexOf(value);

            if (index > -1) {
                this.aclRules.splice(index, 1);
                await AclRequests.updateRule(this.aclType, this.aclRules);
            }
        },

        async createUserRules() {
            const modules = this.$store.getters['modules/modules'];
            const installed = modules.filter(m => m.installed);

            const addRules = installed.map(m => {
                return {
                    role: 'user',
                    action: ['*'],
                    resource: `${m.name}*`,
                    attributes: ['non-private']
                };
            });

            await this.$store.dispatch('permissions/editRules', { add: addRules });
            await this.getRules(this.type);
        },

        cancel() {
            this.$router.push({ path: '/dashboard/acl/' });
        },

        checkUserPermission(alertController) {
            this.hasUserAllRule = this.hasUserRule();
            if (this.hasUserAllRule) {
                alertController.showAlert('warning');
            } else {
                alertController.closeAlert();
            }
        },

        async removeUserPermissions(alertController) {
            await this.removeUserAllAccessPermission();
            await this.getRules(this.type);
            this.checkUserPermission(alertController);
            this.showPostRemoveUserPermissionDialog();
        },

        showPostRemoveUserPermissionDialog() {
            const title = 'Generic user rule removed';
            const body = 'The rule that allowed all users to use all connectors has been removed.' +
                ' Now you can control which connectors are available to your users. You can also use the ' +
                '"ADD USER RULES FOR INSTALLED connectors" button to create a user access rule for every ' +
                'connector currently installed in your system.';
            this.confirmDialog.controller.showDialog(title, body);
        }
    }
};
</script>

<style lang="scss" scoped>
  .edit-icon {
      cursor: pointer;
  }

  .justify-right {
      justify-content: flex-end;
  }
</style>
