export default {

    fileToBuffer(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = function(e) {
                resolve(e.target.result);
            };

            reader.onerror = function(event) {
                reject(reader.error);
            };

            reader.readAsArrayBuffer(file);
        });
    }
};
