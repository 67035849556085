import mitt from 'mitt';

export default class Poll {
    constructor(statusFn, successCb, rejectCb) {
        this.statusFn = statusFn;
        this.successCb = successCb;
        this.rejectCb = rejectCb || (() => {});

        this.eventEmitter = mitt();
        this.donePromise = null;
    }

    start() {
        this.donePromise = new Promise((resolve, reject) => {
            const interval = setInterval(async () => {
                let status;
                try {
                    status = await this.statusFn();
                } catch (err) {
                    clearInterval(interval);
                    reject(err);
                    return;
                }

                const err = this.rejectCb(status);
                if (err) {
                    clearInterval(interval);
                    reject(err);
                }

                this.eventEmitter.emit('status', status);
                const finished = this.successCb(status);

                if (finished) {
                    clearInterval(interval);
                    resolve(finished);
                }
            }, 5000);
        });
    }

    waitForCompletion() {
        return this.donePromise;
    }

    onStatus(callback) {
        this.eventEmitter.on('status', callback);
    }
}
