<template>
    <v-dialog
        :value="show"
        width="500"
        @input="close"
    >
        <v-card>
            <v-card-title>
                <div class="module-upload-modal__title">
                    Upload new module
                </div>
            </v-card-title>

            <v-card-text>
                <v-alert
                    :value="!!error"
                    type="error"
                    outline
                >
                    {{ error }}
                </v-alert>

                <module-uploader
                    ref="uploader"
                    @file:change="updateFile"
                />
            </v-card-text>

            <v-card-actions>
                <div
                    v-if="loading"
                    class="module-upload-modal__loader-wrapper"
                >
                    Uploading. This could take a few minutes...
                    <v-progress-circular
                        :size="30"
                        color="primary"
                        indeterminate
                    />
                </div>

                <template v-else>
                    <v-btn
                        color="primary"
                        text
                        @click.stop="uploadFile"
                    >
                        Upload
                    </v-btn>
                    <v-btn
                        color="grey"
                        text
                        @click.stop="close"
                    >
                        Cancel
                    </v-btn>
                </template>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ModuleUploader from './Uploader';
export default {
    name: 'ModuleUploadModal',

    components: {
        ModuleUploader
    },

    props: {
        show: {
            type: Boolean,
            default: false
        },

        loading: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            file: null,
            error: null
        };
    },

    methods: {
        updateFile(file) {
            this.error = null;
            if (file.name.endsWith('.zip')) {
                this.file = file;
            } else {
                this.error = 'Only .zip files are valid';
                this.removeFile();
            }
        },

        uploadFile() {
            if (!this.file) {
                this.error = 'Please select a zip file from your system';
            } else {
                this.$emit('upload', this.file);
            }
        },

        removeFile() {
            this.file = null;
            this.$refs.uploader.clearDropzone();
        },

        reset() {
            this.error = null;
            this.removeFile();
        },

        close() {
            this.removeFile();
            this.$emit('close');
        }
    }

};
</script>

<style lang="scss" scoped>
  .module-upload-modal {
    &__title {
      font-weight: bold;
    }

    &__loader-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
</style>
