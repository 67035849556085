<template>
    <v-overlay
        :value="isLoading"
    >
        <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
        />
    </v-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'Spinner',
    computed: {
        ...mapGetters('app', {
            isLoading: 'isLoading'
        })
    }
};
</script>

<style scoped>

</style>
