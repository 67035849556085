import Vue from 'vue';
import Vuetify from 'vuetify';
import theme from './theme';
import 'vuetify/dist/vuetify.min.css';
import '../styles/index.scss';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify, {
    iconfont: 'mdi',
    theme
});
