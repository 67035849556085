export default {
    methods: {
        createConfirmDialog() {
            const properties = {
                show: false,
                title: '',
                body: '',
                callbackPayload: {}
            };

            const controller = {
                showDialog(title, body, callbackPayload) {
                    properties.show = true;
                    properties.title = title;
                    properties.body = body;
                    properties.callbackPayload = callbackPayload;
                },

                closeDialog() {
                    properties.show = false;
                    properties.title = '';
                    properties.body = '';
                    properties.callbackPayload = {};
                }
            };

            return {
                properties,
                controller
            };
        },

        createConfirmDialogLoader() {
            const properties = {
                show: false,
                loading: false,
                title: '',
                body: '',
                callback: null
            };

            const controller = {
                setLoading(state) {
                    properties.loading = state;
                },

                showDialog(title, body, callback) {
                    properties.show = true;
                    properties.title = title;
                    properties.body = body;
                    properties.callback = callback;
                },

                closeDialog() {
                    properties.show = false;
                    properties.title = '';
                    properties.body = '';
                    properties.callback = null;
                }
            };

            return {
                properties,
                controller
            };
        }
    }
};
