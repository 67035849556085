import _ from 'lodash';
import UserRequests from '../utils/requests/user';
import FlowRequests from '../utils/requests/flow';
import RelationshipsMixin from './relationships';

async function loadUsersSingleQuery(flows) {
    let userIds = flows.reduce((ids, flow) => ids.concat(flow.userId), []);
    userIds = _.uniq(userIds).join(',');
    const users = await UserRequests.queryUsers({ userIds });
    flows.forEach(flow => loadMatchingUser.call(this, flow, users));
}

function loadMatchingUser(flow, users) {
    const user = users.find(user => flow.userId === user.id) || {};
    this.$set(flow, 'user', user);
}

export default {
    mixins: [RelationshipsMixin],
    methods: {

        async fetchFlows(params = {}) {
            const query = params.query || {};
            const relationships = params.relationships;
            const flows = await FlowRequests.queryFlows(query);
            if (!_.isEmpty(flows) && !_.isEmpty(relationships)) {
                this.loadRelationships(flows, relationships);
            }
            return flows;
        },

        fetchFlowsCount(params = {}) {
            const query = params.query || {};
            return FlowRequests.queryFlowsCount(query);
        },

        loadUserRelationship(flows) {
            flows.forEach(flow => { this.$set(flow, 'user', {}); });
            loadUsersSingleQuery.call(this, flows);
        },

        fetchTelemetry(flowId) {
            return FlowRequests.getTelemetry(flowId);
        }
    }
};
