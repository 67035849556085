import { render, staticRenderFns } from "./ModulesList.vue?vue&type=template&id=7e73bf27&scoped=true&"
import script from "./ModulesList.vue?vue&type=script&lang=js&"
export * from "./ModulesList.vue?vue&type=script&lang=js&"
import style0 from "./ModulesList.vue?vue&type=style&index=0&id=7e73bf27&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e73bf27",
  null
  
)

export default component.exports