<template>
    <textarea ref="editor" />
</template>

<script>
import CodeMirror from 'codemirror';
import { JSHINT } from 'jshint';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/mode/javascript/javascript';
window.JSHINT = JSHINT;

export default {
    name: 'VEditorJS',

    props: {
        value: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            editor: null
        };
    },

    watch: {
        value(value) {
            const editorValue = this.editor.getValue();
            if (value !== editorValue) {
                this.editor.setValue(value);
            }
        }
    },

    mounted() {
        this.editor = CodeMirror.fromTextArea(this.$refs.editor, {
            lineNumbers: true,
            mode: 'javascript',
            gutters: ['CodeMirror-lint-markers'],
            lint: {
                node: true
            }
        });

        this.editor.setValue(this.value);

        this.editor.on('beforeChange', (_, change) => {
            if (change.origin === 'setValue' && this.editor.getValue() === this.value) {
                change.cancel();
            }
        });

        this.editor.on('change', () => {
            this.$emit('input', this.editor.getValue());
        });
    },

    methods: {
        refresh() {
            this.editor.refresh();
        }
    }
};
</script>

<style scoped>
</style>
