import Vue from 'vue';
import moment from 'moment';

Vue.mixin({
    filters: {
        boolToStr(val) {
            return val ? 'Yes' : 'No';
        },
        formatDate(date, format) {
            return moment(date).format(format);
        },
        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Byte';

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }
    }
});
