import UserRequests from '../../../utils/requests/user';
export default {
    data() {
        return {
            editDialog: {
                show: false,
                currentUser: {}
            }
        };
    },

    methods: {
        editUser(user) {
            this.editDialog.currentUser = user;
            this.editDialog.show = true;
        },

        async updateUser(userId, payload) {
            await UserRequests.updateUser(userId, payload);
            this.reloadUser(userId);
            this.editDialog.show = false;
        }
    }
};
