import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';
import authUtils from './utils/auth';

// Routes
import paths from './paths';

Vue.use(Router);

// Create a new router
const router = new Router({
    mode: 'history',
    routes: paths,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { selector: to.hash };
        }
        return { x: 0, y: 0 };
    }
});

Vue.use(Meta);

router.beforeEach((to, from, next) => {
    if (!authUtils.authenticated(to)) {
        router.replace({ name: 'Login', query: to.query });
    } else {
        next();
    }
});

export default router;
