import ObjectUtils from './object';

function processFilter(filter) {
    if (Array.isArray(filter)) {
        return filter;
    }

    filter = ObjectUtils.trimNullValues(filter);

    if (!ObjectUtils.isEmpty(filter)) {
        filter = JSON.stringify(filter).replace(/[{}"]/g, '');
    }
    return filter;
}

function pagination(page, pageSize) {
    const limit = pageSize;
    const offset = (page - 1) * pageSize;
    return { limit, offset };
}

function processSort(sort) {
    const column = Object.keys(sort)[0];
    if (sort[column] === 'desc') {
        sort[column] = -1;
    }
    if (sort[column] === 'asc') {
        sort[column] = 1;
    }
    return JSON.stringify(sort).replace(/[{}"]/g, '');
}

export default {

    DEFAULT_PAGE_SIZE: 30,

    paginationToLimitOffset: pagination,

    processQuery(query) {
        if (ObjectUtils.isEmpty(query.pattern)) {
            delete query.pattern;
        }

        if (query.filter) {
            query.filter = processFilter(query.filter);
        }

        if (ObjectUtils.isEmpty(query.filter)) {
            delete query.filter;
        }

        if (query.pageSize) {
            const page = query.page || 1;
            const { limit, offset } = pagination(page, query.pageSize);
            query.limit = limit;
            query.offset = offset;
            delete query.page;
            delete query.pageSize;
        }
        if (!ObjectUtils.isEmpty(query.sort)) {
            query.sort = processSort(query.sort);
        }
        return query;
    },

    getComponentUsageQuery(module, group, { stage = null, limit, offset, count }) {
        return {
            componentType: module,
            group: group,
            ...(count ? { count } : {}),
            ...(stage ? { stage } : {}),
            ...(limit ? { limit } : {}),
            ...(offset ? { offset } : {})
        };
    }
};
