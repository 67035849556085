<template>
    <!-- eslint-disable -->
    <confirm-dialog
        :show="show"
        @cancel="$emit('cancel')"
    >
        <template slot="header">
            Breaking changes
        </template>

        <template slot="body">
            This update contains breaking changes. All flows using this connector will be stopped.
            All affected flows must be reconfigured. See the changelog of this connector to check details on what has changed.
        </template>

        <template slot="action-buttons">
            <div
                v-if="stopFlows.loading"
                class="breaking-modal__flows-loader"
            >
                <v-progress-linear
                    v-if="stopFlows.progress.stepsTotal === 0"
                    :indeterminate="true"
                    color="primary"
                />
                <v-progress-linear
                    v-else
                    :value="loaderProgress(stopFlows.progress)"
                    color="primary"
                />

                <div v-if="stopFlows.progress.stepsTotal !== 0">
                    Stopping flows: {{ stopFlows.progress.stepsDone }}
                    of {{ stopFlows.progress.stepsTotal }} flows stopped
                </div>

                <div v-else>
                    Stopping flows...
                </div>

                <v-btn
                    class="mt-2"
                    color="grey"
                    text
                    @click.stop="$emit('cancel-stop-flows')"
                >
                    Cancel operation
                </v-btn>
            </div>

            <template v-else-if="uploading.loading">
                Updating connector
            </template>

            <template v-else>
                <v-btn
                    color="primary"
                    text
                    @click.stop="$emit('confirm')"
                >
                    Confirm
                </v-btn>

                <v-btn
                    color="grey"
                    text
                    @click.stop="$emit('cancel')"
                >
                    Cancel
                </v-btn>
            </template>
        </template>
    </confirm-dialog>
</template>

<script>
import ConfirmDialog from '../../components/common/ConfirmDialog/Basic';

export default {
    name: 'BreakingUpdateModal',

    components: {
        ConfirmDialog
    },

    extends: ConfirmDialog,

    props: {
        stopFlows: {
            type: Object,
            default: () => ({
                loading: false,
                progress: {
                    stepsDone: 0,
                    stepsTotal: 0
                }
            })
        },
        uploading: {
            type: Object,
            default: () => ({
                loading: false
            })
        }
    },

    methods: {
        loaderProgress(progress) {
            return progress.stepsTotal ? (progress.stepsDone / progress.stepsTotal) * 100 : 0;
        }
    }
};
</script>

<style scoped>
.breaking-modal__flows-loader {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 12px;
    font-weight: bold;
}

</style>
