// Styles
import './VOverlay.sass';

// Mixins
import Colorable from 'vuetify/lib/mixins/colorable';
import Themeable from 'vuetify/lib/mixins/themeable';
import Toggleable from 'vuetify/lib/mixins/toggleable';

// Utilities
import mixins from 'vuetify/lib/util/mixins';

/* @vue/component */
export default mixins(
    Colorable,
    Themeable,
    Toggleable
).extend({
    name: 'v-overlay',

    props: {
        absolute: Boolean,
        color: {
            type: String,
            default: '#ffffff'
        },
        dark: {
            type: Boolean,
            default: true
        },
        opacity: {
            type: [Number, String],
            default: 0.50
        },
        value: {
            default: true
        },
        zIndex: {
            type: [Number, String],
            default: 5
        }
    },

    computed: {
        __scrim() {
            const data = this.setBackgroundColor(this.color, {
                staticClass: 'v-overlay__scrim',
                style: {
                    opacity: this.computedOpacity
                }
            });

            return this.$createElement('div', data);
        },
        classes() {
            return {
                'v-overlay--absolute': this.absolute,
                'v-overlay--active': this.isActive,
                ...this.themeClasses
            };
        },
        computedOpacity() {
            return this.isActive ? this.opacity : 0;
        },
        styles() {
            return {
                zIndex: this.zIndex
            };
        }
    },

    methods: {
        genContent() {
            return this.$createElement('div', {
                staticClass: 'v-overlay__content'
            }, this.$slots.default);
        }
    },

    render(h) {
        const children = [this.__scrim];

        if (this.isActive) children.push(this.genContent());

        return h('div', {
            staticClass: 'v-overlay',
            class: this.classes,
            style: this.styles
        }, children);
    }
});
