<template>
    <confirm-dialog-loader
        :loading="loading"
        :show="show"
        @confirm="$emit('confirm')"
        @cancel="$emit('close')"
    >
        <h4 slot="header">
            Confirm action
        </h4>
        <div slot="body">
            <template v-if="hasFallbackDefinition">
                Are you sure you want to delete this quota? This will revert the quota definition to the default one.
            </template>
            <template v-else>
                Are you sure you want to delete this quota? The definition will be lost and cannot be recovered.
            </template>
        </div>
    </confirm-dialog-loader>
</template>

<script>
import ConfirmDialogLoader from '../../../components/common/ConfirmDialog/WithLoader';
export default {
    name: 'DeleteQuotaDialog',

    components: {
        ConfirmDialogLoader
    },

    props: {
        loading: {
            type: Boolean,
            default: false
        },

        show: {
            type: Boolean,
            default: false
        },

        hasFallbackDefinition: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped>

</style>
