<template>
    <v-layout wrap>
        <v-flex
            xs12
            class="module-filters__title"
        >
            Filters:
        </v-flex>

        <v-flex md6>
            <v-text-field
                v-model="value.name"
                label="Name"
                @keyup="debouncedChange"
            />
        </v-flex>

        <v-flex md6>
            <v-select
                v-model="value.status"
                :items="statusOptions"
                label="Status"
                @change="change"
            />
        </v-flex>

        <v-flex md6>
            <v-select
                v-model="value.update"
                :items="updateOptions"
                label="Update status"
                @change="change"
            />
        </v-flex>

        <v-flex md6>
            <v-select
                v-model="value.vendor"
                :items="vendorOptions"
                label="Vendor"
                @change="change"
            />
        </v-flex>
    </v-layout>
</template>

<script>
import _ from 'lodash';

export default {
    name: 'ModuleFilters',

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            statusOptions: [
                { text: 'All', value: 'all' },
                { text: 'Installed', value: 'installed' },
                { text: 'Not installed', value: 'notInstalled' }
            ],
            updateOptions: [
                { text: 'All', value: 'all' },
                { text: 'Update available', value: 'updateAvailable' },
                { text: 'Up to date', value: 'updateNotAvailable' }
            ],
            vendorOptions: [
                { text: 'All', value: 'all' },
                { text: 'Appmixer', value: 'appmixer' },
                { text: 'Others', value: 'others' }
            ],
            debouncedChange: _.debounce(this.change, 300)
        };
    },

    methods: {
        change() {
            const removeEmptySpaces = this.value.name.trim();
            this.$emit('input', { ...this.value, name: removeEmptySpaces });
        }
    }
};
</script>

<style lang="scss" scoped>
.module-filters {
  &__title {
    font-size: 18px;
  }
}

</style>
