import appmixer from '../../plugins/appmixer';

export default {

    async getDocsLink(module) {
        try {
            // if there is a documentation page for this module, return the link
            const { url } = await appmixer.api.request({
                url: `${appmixer.api.baseUrl}/system/module-docs-link/${module}`,
                method: 'GET'
            });

            return url;
        } catch (err) {
            // if the URL does not exist, return null
            return null;
        }
    }
};
