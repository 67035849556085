<template>
    <v-app>
        <spinner />
        <core-toolbar />

        <core-drawer />

        <core-view />
    </v-app>
</template>

<script>
import Spinner from '../components/common/Spinner';
export default {
    name: 'Dashboard',
    components: { Spinner },
    created() {
        this.$store.dispatch('user/queryUser');
        this.$store.dispatch('engine/queryVersion');
    }
};
</script>

<style scoped>

</style>
