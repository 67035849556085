import AppsRequests from '../../../utils/requests/apps';
import Poll from '../../../utils/poll';
import createStorage from '../../../services/LocalStorage';

const ticketStorage = createStorage();

export default {

    constants: {
        NOT_UPLOADING: 'notUploading',
        UPLOADING: 'uploading'
    },

    saveTicket(name, ticket) {
        ticketStorage.set(name, ticket);
    },

    async status(name) {
        const ticket = ticketStorage.get(name);
        if (!ticket) {
            return { status: this.constants.NOT_UPLOADING };
        }

        const response = await this.statusByTicket(ticket);
        if (response.status === this.constants.NOT_UPLOADING) {
            ticketStorage.remove(name);
        }
        return response;
    },

    async statusByTicket(ticket) {
        try {
            const status = await AppsRequests.uploadStatus(ticket);
            const { finished } = status;
            if (finished) {
                return status;
            }
            return { status: this.constants.UPLOADING };
        } catch (err) {
            if (err.response.status === 404) {
                return { status: this.constants.NOT_UPLOADING };
            }
            throw err;
        }
    },

    waitForUpload(name) {
        const statusFn = () => {
            return this.status(name);
        };
        const poll = new Poll(statusFn, this.uploadSuccessCb, this.uploadErrorCb.bind(this));
        return poll.waitForCompletion();
    },

    waitForUploadByTicket(ticket) {
        const statusFn = () => {
            return this.statusByTicket(ticket);
        };

        const poll = new Poll(statusFn, this.uploadSuccessCb, this.uploadErrorCb.bind(this));
        poll.start();
        return poll.waitForCompletion();
    },

    uploadSuccessCb(status) {
        const { finished } = status;
        return finished;
    },

    uploadErrorCb(status) {
        const { err, status: uploadStatus } = status;
        if (err) {
            return err;
        }

        if (uploadStatus === this.constants.NOT_UPLOADING) {
            return 'The connector installation has timed out. Please try again';
        }
    }
};
