import QueryUtils from '../query';
import appmixer from '../../plugins/appmixer';

export default {

    queryPublicFiles(query = {}) {
        query = QueryUtils.processQuery(query);
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/public-files`,
            method: 'GET',
            params: query
        });
    },

    async upload(filename, file) {
        const form = new FormData();
        form.append('filename', filename);
        form.append('file', file);

        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/public-files`,
            method: 'POST',
            data: form,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    remove(filename) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/public-files/${filename}`,
            method: 'DELETE'
        });
    }
};
