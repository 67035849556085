<template>
    <v-data-table
        :headers="headers"
        :items="serviceItems"
        :loading-data="loading"
        sort-icon="mdi-menu-down"
        no-data-text="No connector configurations found"
        hide-actions
    >
        <template
            slot="headerCell"
            slot-scope="{ header }"
        >
            <span
                class="subheading font-weight-light text-primary text--darken-3"
                v-text="header.text"
            />
        </template>

        <template
            slot="items"
            slot-scope="{ item }"
        >
            <template v-if="item.editing">
                <td>
                    <v-text-field
                        v-model="editInputs.key"
                        placeholder="Key"
                        type="text"
                    />
                    <div
                        v-if="editSubmitted && !$v.editInputs.key.required"
                        class="invalid-feedback"
                    >
                        Key is required
                    </div>
                    <div
                        v-if="editSubmitted && !$v.editInputs.key.pattern"
                        class="invalid-feedback"
                    >
                        Key cannot be "serviceId"
                    </div>
                    <div
                        v-if="editSubmitted && !$v.editInputs.key.isUnique"
                        class="invalid-feedback"
                    >
                        Key already exists
                    </div>
                </td>

                <td>
                    <v-text-field
                        v-model="editInputs.value"
                        placeholder="Value"
                        :type="types[editInputs.key]"
                    />
                </td>

                <td>
                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-icon
                                class="mr-3"
                                v-on="on"
                                @click="confirmEdit()"
                            >
                                mdi-check
                            </v-icon>
                        </template>
                        <span class="text-white">Confirm</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-icon
                                v-on="on"
                                @click="cancelEdit()"
                            >
                                mdi-close
                            </v-icon>
                        </template>
                        <span class="text-white">Cancel</span>
                    </v-tooltip>
                </td>
            </template>

            <template v-else-if="item.creating">
                <td>
                    <v-text-field
                        v-model="createInputs.key"
                        placeholder="Key"
                        type="text"
                    />
                    <div
                        v-if="createSubmitted && !$v.createInputs.key.required"
                        class="invalid-feedback"
                    >
                        Key is required
                    </div>
                    <div
                        v-if="createSubmitted && !$v.createInputs.key.pattern"
                        class="invalid-feedback"
                    >
                        Key cannot be "serviceId"
                    </div>
                    <div
                        v-if="createSubmitted && !$v.createInputs.key.isUnique"
                        class="invalid-feedback"
                    >
                        Key already exists
                    </div>
                </td>

                <td>
                    <v-text-field
                        v-model="createInputs.value"
                        placeholder="Value"
                        type="text"
                    />
                    <div
                        v-if="createSubmitted && !$v.createInputs.value.required"
                        class="invalid-feedback"
                    >
                        Value is required
                    </div>
                </td>

                <td>
                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-icon
                                class="mr-3"
                                v-on="on"
                                @click="confirmCreate()"
                            >
                                mdi-check
                            </v-icon>
                        </template>
                        <span class="text-white">Confirm</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-icon
                                v-on="on"
                                @click="cancelCreate()"
                            >
                                mdi-close
                            </v-icon>
                        </template>
                        <span class="text-white">Cancel</span>
                    </v-tooltip>
                </td>
            </template>

            <template
                v-else
            >
                <td>{{ item.key }}</td>

                <td>{{ item.value }}</td>

                <td>
                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-icon
                                class="mr-3"
                                v-on="on"
                                @click="edit(item)"
                            >
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span class="text-white">Edit</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-icon
                                v-on="on"
                                @click="remove(item)"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                        <span class="text-white">Delete</span>
                    </v-tooltip>
                </td>
            </template>
        </template>
    </v-data-table>
</template>

<script>
import { required, not } from 'vuelidate/lib/validators';
import { pattern } from '../../utils/validators';

export default {
    name: 'ServiceConfigTable',

    validations: {
        createInputs: {
            key: {
                required,
                pattern: not(pattern('^serviceId$')),
                isUnique(value) {
                    return !(this.serviceItems.find(i => i.key === value));
                }
            },
            value: {
                required
            }
        },
        editInputs: {
            key: {
                required,
                pattern: not(pattern('serviceId$')),
                isUnique(value) {
                    return this.editing === value ||
              !(this.serviceItems.find(i => i.key === value));
                }
            },
            value: {
                required
            }
        }
    },

    props: {
        loading: {
            type: Boolean,
            default: false
        },

        types: {
            type: Array,
            default: () => []
        },

        service: {
            type: Object,
            default: () => ({})
        },

        creating: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            headers: [
                {
                    sortable: true,
                    text: 'Key',
                    value: 'key'
                },
                {
                    sortable: false,
                    text: 'Value',
                    value: 'value'
                },
                {
                    sortable: false,
                    text: 'Actions',
                    value: 'actions'
                }
            ],
            editing: null,
            editInputs: {
                key: null,
                value: null
            },
            editSubmitted: false,

            createInputs: {
                key: null,
                value: null
            },
            createSubmitted: false
        };
    },

    computed: {
        serviceItems() {
            return this.service ? this.getServiceEntries() : [];
        }
    },

    watch: {
        createInputs: {
            deep: true,
            handler() {
                this.createSubmitted = false;
            }
        },

        editInputs: {
            deep: true,
            handler() {
                this.editSubmitted = false;
            }
        }
    },

    methods: {
        getServiceEntries() {
            const entries = Object.entries({ ...this.service }).reduce((acc, [key, value]) => {
                if (key !== 'serviceId') {
                    acc.push({ key, value, editing: key === this.editing });
                }
                return acc;
            }, []);

            if (this.creating) {
                entries.push({ key: '', value: '', creating: true });
            }
            return entries;
        },

        edit(item) {
            this.editing = item.key;
            this.editInputs.key = item.key;
            this.editInputs.value = item.value;
        },

        cancelEdit() {
            this.editing = null;
            this.editInputs.key = null;
            this.editInputs.value = null;
        },

        confirmEdit() {
            this.editSubmitted = true;
            if (!this.$v.editInputs.$invalid) {
                this.$emit('update', {
                    oldKey: this.editing,
                    key: this.editInputs.key,
                    value: this.editInputs.value
                });
                this.editing = null;
                this.editInputs.key = null;
                this.editInputs.value = null;
            }
        },

        confirmCreate() {
            this.createSubmitted = true;
            if (!this.$v.createInputs.$invalid) {
                this.$emit('create', { key: this.createInputs.key, value: this.createInputs.value });
                this.createInputs.key = null;
                this.createInputs.value = null;
            }
        },

        cancelCreate() {
            this.createInputs.key = null;
            this.createInputs.value = null;
            this.$emit('cancel-create');
        },

        remove(item) {
            this.$emit('delete', item.key);
        }
    }
};
</script>

<style lang="scss" scoped>
.invalid-feedback {
  width: 100%;
  margin-top: .25rem;
  font-size: 100%;
  color: #dc3545;
}
</style>
