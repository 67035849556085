<template>
    <div>
        <vue-dropzone
            id="dropzone"
            ref="dropzone"
            :options="dropzoneOptions"
            :use-custom-slot="true"
            @vdropzone-file-added="addFile"
        >
            <div>Click to select a file from your system or drag and drop the file here</div>
        </vue-dropzone>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    name: 'Upload',

    components: {
        vueDropzone: vue2Dropzone
    },

    data() {
        return {
            error: null,
            dropzoneOptions: {
                url: 'https://enterprise-chorus-engine.rainbird.ai:443',
                autoQueue: false,
                maxFiles: 1,
                addRemoveLinks: true
            }
        };
    },

    methods: {
        addFile(file) {
            const currentFiles = this.$refs.dropzone.getAcceptedFiles();
            currentFiles.forEach(f => this.$refs.dropzone.removeFile(f));
            this.$emit('file:change', file);
        },

        clearDropzone() {
            this.$refs.dropzone.removeAllFiles();
        }
    }
};
</script>

<style lang="scss" scoped>
  #dropzone /deep/ .dz-progress {
    display: none;
  }
</style>
